import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ServicesState } from '../_reducers/service.reducers';

import * as fromRouter from '@ngrx/router-store';

export const selectServiceState = createFeatureSelector<ServicesState>('services');

export interface State {
    router: fromRouter.RouterReducerState<any>;
}

const selectRouter = createFeatureSelector<State,
    fromRouter.RouterReducerState<any>>('router');

export const {
    selectCurrentRoute,   // select the current route
    selectQueryParams,    // select the current route query params
    selectQueryParam,     // factory function to select a query param
    selectRouteParams,    // select the current route params
    selectRouteParam,     // factory function to select a route param
    selectRouteData,      // select the current route data
    selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectCoreServiceId = selectRouteParam('coreServiceId');

export const selectAllServicesEntities = createSelector(
    selectServiceState,
    selectCoreServiceId,
    service => service.entities
);

export const selectAllServices = createSelector(
    selectAllServicesEntities,
    (services) => Object.keys(services).map(serviceId => services[serviceId])
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
);

export const selectService = createSelector(
    selectAllServicesEntities,
    selectCoreServiceId,
    (services, servicesId) => services[servicesId]
);

export const selectLinks = createSelector(
    selectServiceState,
    (services) => services.links
);

export const selectMeta = createSelector(
    selectServiceState,
    (services) => services.meta
);
