<div class="main full-height sm-p-t-30 d-flex flex-column">
    <div class="d-flex flex-column full-height form-container">

        <dg-logo></dg-logo>
        <p>
            Reset your datagro account's password.
            Please select a password, which ensures the unknown login sessions.
        </p>

        <dg-auth-notice noticeBoardId="reset-password"></dg-auth-notice>

        <form (submit)="submit()" [formGroup]="resetPassword" class="p-t-15" role="form">

            <div class="row">
                <div class="col-md-12">
                    <div [class.has-error]="resetPassword.get('password').invalid && (resetPassword.get('password').dirty || resetPassword.get('password').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="Password">New Password</label>
                        <input class="form-control" formControlName="password" id="Password" placeholder="New Password"
                               type="password">
                    </div>
                    <div
                            *ngIf="resetPassword.get('password').invalid && (resetPassword.get('password').dirty || resetPassword.get('password').touched)">
                        <label *ngIf="resetPassword.get('password').errors?.required" class="error">This field is
                            required.</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div [class.has-error]="resetPassword.get('password_confirmation').invalid && (resetPassword.get('password_confirmation').dirty || resetPassword.get('password_confirmation').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="ConfirmPassword">Confirm Password</label>
                        <input class="form-control" formControlName="password_confirmation" id="ConfirmPassword"
                               placeholder="ConfirmPassword"
                               type="password">
                    </div>
                    <div
                            *ngIf="resetPassword.get('password_confirmation').invalid && (resetPassword.get('password_confirmation').dirty || resetPassword.get('password_confirmation').touched)">
                        <label *ngIf="resetPassword.get('password_confirmation').errors?.required" class="error">This
                            field is
                            required.</label>
                    </div>
                </div>
            </div>

            <div class="row m-t-10">
                <div class="col-lg-6">
                    <button [class.disabled]="resetPassword.invalid || loading"
                            [disabled]="resetPassword.invalid || loading"
                            class="btn btn-primary btn-cons"
                            type="submit">Reset Password
                    </button>
                </div>
            </div>
        </form>

    </div>

    <div class="form-note"> Back to <a routerLink="/auth/login">Login.</a></div>
</div>
