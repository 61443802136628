import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BlankLayoutComponent } from './blank-layout.component';
import {LogoModule} from '../../logo/logo.module';



@NgModule({
  declarations: [
    BlankLayoutComponent
  ],
  exports: [
    BlankLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LogoModule
  ]
})
export class BlankLayoutModule { }
