// Angular
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
import { AuthNotice } from '../auth-notice.interface';
import { AuthNoticeService } from '../auth-notice.service';
// Auth

@Component({
    selector: 'dg-auth-notice',
    templateUrl: './auth-notice.component.html',
})
export class AuthNoticeComponent implements OnInit, OnDestroy {
    @Input() noticeBoardId: string;
    @Output() type: any;
    @Output() notice: string | string[] = null;
    // Private properties
    private subscriptions: Subscription[] = [];

    container: string;

    /**
     * Component Constructure
     *
     * @param authNoticeService: AuthNoticeService
     * @param cdr: ChangeDetectorRef
     */
    constructor(public authNoticeService: AuthNoticeService, private cdr: ChangeDetectorRef) {
    }

    /*
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

    /**
     * On init
     */
    ngOnInit(): void {
        this.subscriptions.push(this.authNoticeService.onNoticeChanged$.subscribe(
            (notice: AuthNotice) => {
                notice = Object.assign({}, {message: '', type: ''}, notice);
                this.notice = notice.message;
                this.type = notice.type;
                this.container = notice.noticeBoardId;
                this.cdr.markForCheck();
            }
        ));
    }

    public typeof(variable: any): string {
        return typeof variable === 'object' ? Array.isArray(variable) ? 'array' : 'object' : typeof variable;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }
}
