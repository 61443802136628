import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { _MESSAGE_DEFAULT_CONFIG_PROVIDER } from './notification-config';
import { NotificationContainerComponent } from './notification-container.component';
import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';

const providers = [_MESSAGE_DEFAULT_CONFIG_PROVIDER];

@NgModule({
  imports: [ CommonModule, OverlayModule ],
  declarations: [ NotificationContainerComponent, NotificationComponent ],
  providers: [ NotificationService ],
  entryComponents: [ NotificationContainerComponent ]
})
export class NotificationModule {}
