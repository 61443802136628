<!-- WRAP LAYOUT IF BOXED -->
<div class="container" *ngIf="boxed$; else basicLayoutBlock">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>
<ng-template #basicLayoutBlock>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>

<!-- YOUR LAYOUT CONTENT GOES INSIDE HERE -->
<ng-template #contentTpl>
  <!-- PAGE SIDEBAR -->
  <dg-sidebar>
    <ng-template #sideBarOverlay>
      <div class="row">
        <div class="col-xs-6 no-padding">
          <a href="javascript:void(0)" class="p-l-40"><img src="assets/img/demo/social_app.svg" alt="socail">
          </a>
        </div>
        <div class="col-xs-6 no-padding">
          <a href="javascript:void(0)" class="p-l-10"><img src="assets/img/demo/email_app.svg" alt="socail">
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6 m-t-20 no-padding">
          <a href="javascript:void(0)" class="p-l-40"><img src="assets/img/demo/calendar_app.svg" alt="socail">
          </a>
        </div>
        <div class="col-xs-6 m-t-20 no-padding">
          <a href="javascript:void(0)" class="p-l-10"><img src="assets/img/demo/add_more.svg" alt="socail">
          </a>
        </div>
      </div>
    </ng-template>
    <ng-template #sideBarHeader>
      <dg-logo [origin]="'sideBarHeader'"></dg-logo>
      <!--<div class="sidebar-header-controls">
        <button type="button" class="btn btn-icon-link invert sidebar-slide-toggle m-l-20 m-r-10"
                [class.active]="menuDrawerOpen$" (click)="toggleMenuDrawer()">
          <i class="pg-icon">chevron_down</i>
        </button>
        <button type="button"
                class="btn btn-icon-link invert d-lg-inline-block d-xlg-inline-block d-md-inline-block d-sm-none d-none"
                data-toggle-pin="sidebar" (click)="toggleMenuPin($event)">
          <i class="pg-icon"></i>
        </button>
      </div>-->
    </ng-template>
    <ng-template #menuItems>
      <dg-menu-items [Items]="menuLinks"></dg-menu-items>
    </ng-template>
  </dg-sidebar>
  <!-- PAGE CONTAINER -->
  <dg-page-container>
    <dg-header [boxed]="boxed$">
      <!-- START MOBILE SIDEBAR TOGGLE -->
      <a href="javascript:void(0);" class="btn-icon-link toggle-sidebar d-lg-none" (click)="toggleMobileSidebar()">
        <i class="pg-icon">menu</i>
      </a>
      <!-- END MOBILE SIDEBAR TOGGLE -->
      <div class="">
        <!-- START EMAIL MOBILE TOGGLE -->
        <a href="javascript:void(0);" class="toggle-secondary-sidebar align-items-center"
           (click)="toggleSecondarySideBar()" *ngIf="layoutOption$ === 'email'">
          <span class="d-flex align-items-center">
            Inbox <span class="text-info">(12)</span> <span class="pg-icon">drop_down</span>
          </span>
        </a>
        <!-- END EMAIL MOBILE TOGGLE -->

        <div class="brand inline" [class.d-none]="layoutOption$ === 'email'">
          <dg-logo [origin]="'sideBarHeader'"></dg-logo>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <!-- START User Info-->
        <div class="dropdown pull-right" dropdown>
          <button class="profile-dropdown-toggle" type="button" dropdownToggle id="profileDropdown"
                  aria-label="profile dropdown">
            <span class="thumbnail-wrapper d32 circular inline">
              <ngx-avatar *ngIf="user$ | async" [name]="(user$ | async).name"  [ngStyle]="{ height: '32px', width: '32px' }" [initialsSize]="32" [size]="32"></ngx-avatar>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right profile-dropdown" *dropdownMenu role="menu">
            <a href="#" class="dropdown-item"><span>Signed in as <br /><b *ngIf="user$ | async">{{ (user$ | async).name }}</b></span></a>
            <div class="dropdown-divider"></div>
            <a [routerLink]="['/user/dashboard']" class="dropdown-item">Dashboard</a>
            <a [routerLink]="['/user/profile']" class="dropdown-item">Your Profile</a>
            <a [routerLink]="['/user/plans']" class="dropdown-item">Purchase Plans</a>
            <a [routerLink]="['/user/transactions']" class="dropdown-item">Your Transactions</a>
            <!--<div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">Features</a>
            <a href="#" class="dropdown-item">Help</a>
            <a href="#" class="dropdown-item">Settings</a>-->
            <a class="dropdown-item" (click)="logout()">Logout</a>
            <!--<div class="dropdown-divider"></div>
            <span class="dropdown-item fs-12 hint-text">Last edited by David<br />on Friday at 5:27PM</span>-->
          </div>
        </div>
        <!-- END User Info-->
        <a href="javascript:void(0)" class="header-icon btn-icon-link m-l-5 sm-no-margin d-inline-block btn-icon-link"
           (click)="openQuickView($event)">
          <i class="material-icons">
            notifications_none
          </i>
          <span class="bubble"></span>
        </a>
      </div>
    </dg-header>
    <div class="page-content-wrapper {{pageContainerClass$}}">
      <!-- START PAGE CONTENT -->
      <div class="content {{contentClass$}}">
        <router-outlet #o="outlet"></router-outlet>
      </div>
      <!-- END PAGE CONTENT -->
      <!-- START COPYRIGHT -->
      <ng-template [ngIf]="footer$">
        <div class=" container-fluid container-fixed-lg footer">
          <!--<div class="copyright sm-text-center">
            <p class="small no-margin pull-left sm-pull-reset">
              <span class="hint-text">Copyright &copy; 2017</span>&nbsp;<span
              class="font-montserrat">REVOX</span>.&nbsp;<span class="hint-text">All rights
                reserved.</span> <span class="sm-block"><a class="m-l-10 m-r-10" href="javascript:void(0)">Terms of
                  use</a> <span class="muted">|</span> <a class="m-l-10" href="javascript:void(0)">Privacy
                  Policy</a></span>
            </p>

            <p class="small no-margin pull-right sm-pull-reset">
              Hand-crafted <span class="hint-text">&amp; made with Love</span>
            </p>

            <div class="clearfix">
            </div>
          </div>-->
        </div>
        <!-- END COPYRIGHT -->
      </ng-template>
    </div>
  </dg-page-container>
  <!-- QUICKSEARCH -->
<!--  <app-search-overlay></app-search-overlay>-->
  <!-- QUICKVIEW -->
  <dg-quickview></dg-quickview>
</ng-template>
