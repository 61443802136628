import {Component, OnInit, OnDestroy, Input, Inject, PLATFORM_ID} from '@angular/core';
import { ToggleService } from '../../services/toggler.service';
import {isPlatformBrowser} from '@angular/common';

declare var pg: any;

@Component({
  selector: 'dg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  headerClass$ = '';
  isHorizontalLayout: false;
  service$;
  @Input()
  boxed = false;

  @Input()
  extraClass = '';

  constructor(private toggler: ToggleService, @Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isHorizontalLayout = pg.isHorizontalLayout;
    }
    this.service$ = this.toggler.headerClass.subscribe(state => {
      this.headerClass$ = state;
    });
  }

  ngOnDestroy(): void {
    if (this.service$) {
      this.service$.unsubscribe();
    }
  }
}
