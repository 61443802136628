import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BlankLayoutComponent } from '../layout/blank-layout/blank-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from '../shared/shared.module';
import { LogoModule } from '../logo/logo.module';
import { AccessGuard, AuthEffects, AuthGuard, authReducer } from '../../@auth';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthService } from '../../services/auth.service';
import { NoticeModule } from '../notice/notice.module';
import { AuthUserService } from '../../services/auth-user.service';

const routes: Routes = [
    {
        path: '',
        component: BlankLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                component: LoginComponent,
                data: { returnUrl: '' }
            },
            {
                path: 'register',
                component: RegisterComponent
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'verify-email',
                component: EmailVerificationComponent,
                data: {
                    title: 'Email Verification'
                },
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
                data: {
                    title: 'Reset Password'
                },
            }
        ]
    }
];

@NgModule({
    declarations: [
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        EmailVerificationComponent,
        ResetPasswordComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule,
        LogoModule,

        StoreModule.forFeature('auth', authReducer),
        EffectsModule.forFeature([ AuthEffects ]),
        NoticeModule,
    ]
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: AuthModule,
            providers: [
                AuthService,
                AuthGuard,
                AccessGuard,
                AuthUserService
            ]
        };
    }
}
