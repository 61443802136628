import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CoreService, CoreServiceModel } from '../modules/user/store/_models/core-service.model';
import { ApiResponse } from '../interfaces/api-response.interface';


const API_CORE_SERVICES_URL = `${ environment.apiEndpoint }service/list`;
const CREATE_CORE_SERVICES_URL = `${ environment.apiEndpoint }service/create`;
const DELETE_CORE_SERVICES_URL = `${ environment.apiEndpoint }service/`;

@Injectable()
export class CoreServiceService {

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
    }

    // CREATE =>  POST: add a new service to the server
    createCoreService(serviceName: string): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(CREATE_CORE_SERVICES_URL, { name: serviceName });
    }

    // DELETE => delete the service from the server
    deleteCoreService(serviceId: string | number): Observable<CoreService> {
        const url = `${ DELETE_CORE_SERVICES_URL }${ serviceId }/remove`;
        return this.http.delete<CoreService>(url);
    }

    // READ => read all core service from the server
    getAllCoreServices(): Observable<CoreServiceModel> {
        return this.http.get<CoreServiceModel>(`${ API_CORE_SERVICES_URL }`);
    }
}
