// Angular
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, Register, UserLoaded, UserRequested } from '../_actions/auth.actions';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { AppState } from '../../reducers';
import { isPlatformBrowser } from '@angular/common';
import { AllCoreServiceRequested } from '../../modules/user/store/_actions/core-service.actions';
import { AllNotificationsRequested } from '../../modules/user/store/_actions/notification.actions';
import { AuthUserService } from '../../services/auth-user.service';
import { PusherHandlerService } from '../../services/pusher-handler.service';

@Injectable()
export class AuthEffects {
    @Effect({ dispatch: false })
    login$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(action => {
            if ( action.payload.authToken ) {
                if ( isPlatformBrowser(this.platformId) ) {
                    localStorage.setItem(environment.authTokenKey, action.payload.authToken);
                }
            }
            this.store.dispatch(new UserRequested());
            this.store.dispatch(new AllCoreServiceRequested());
        }),
    );

    @Effect({ dispatch: false })
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
            if ( isPlatformBrowser(this.platformId) ) {
                localStorage.removeItem(environment.authTokenKey);
            }
            // this.router.navigate([ '' ]);
            window.location.href = '/auth/login';
        })
    );

    @Effect({ dispatch: false })
    register$ = this.actions$.pipe(
        ofType<Register>(AuthActionTypes.Register),
        tap(action => {
            if ( isPlatformBrowser(this.platformId) ) {
                localStorage.setItem(environment.authTokenKey, action.payload.authToken);
            }
        })
    );

    @Effect({ dispatch: false })
    userLoaded$ = this.actions$.pipe(
        ofType<Register>(AuthActionTypes.UserLoaded),
        tap(action => {
            if ( isPlatformBrowser(this.platformId) && action.payload && (action.payload as any).user ) {
                this.pusher.bindUserChannel((action.payload as any).user.userId);
                this.pusher.bindNotificationChannel(`${(action.payload as any).user.userId}`);
            }
        })
    );

    @Effect({ dispatch: false })
    loadUser$ = this.actions$
        .pipe(
            ofType<UserRequested>(AuthActionTypes.UserRequested),
            withLatestFrom(this.store.pipe(select(isUserLoaded))),
            filter(([ action, isUserLoaded$ ]) => !isUserLoaded$),
            mergeMap(([ action, isUserLoaded$ ]) => this.authUserService.getCombinedApi()),
            tap(apiResponse => {
                if ( apiResponse ) {
                    this.store.dispatch(new UserLoaded({ user: apiResponse[0] }));
                } else {
                    this.store.dispatch(new Logout());
                }
            })
        );

    @Effect()
    init$: Observable<Action> = defer(() => {
        let userToken = null;
        if ( isPlatformBrowser(this.platformId) ) {
            userToken = localStorage.getItem(environment.authTokenKey);
        }
        let observableResult = of({ type: 'NO_ACTION' });
        if ( userToken ) {
            observableResult = of(new Login({ authToken: userToken }));
        }
        return observableResult;
    });

    private returnUrl: string;

    constructor(private actions$: Actions,
                private router: Router,
                private authUserService: AuthUserService,
                private auth: AuthService,
                private store: Store<AppState>,
                private pusher: PusherHandlerService,
                @Inject(PLATFORM_ID) private platformId: object) {

        this.router.events.subscribe(event => {
            if ( event instanceof NavigationEnd ) {
                this.returnUrl = event.url;
            }
        });
    }
}
