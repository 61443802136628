<div class="main full-height sm-p-t-30 d-flex flex-column w-100">
    <div class="d-flex flex-column full-height form-container w-100">

        <dg-logo></dg-logo>
        <p>
            Create a datagro account.
        </p>
        <dg-auth-notice noticeBoardId="register"></dg-auth-notice>
        <form (submit)="submit()" [formGroup]="registerForm" class="p-t-15" role="form">

            <div class="row">
                <div class="col-md-6">
                    <div [class.has-error]="registerForm.get('firstName').invalid && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="firstName">First Name</label>
                        <input class="form-control" formControlName="firstName" id="firstName" placeholder="John"
                               type="text">
                    </div>
                    <div
                            *ngIf="registerForm.get('firstName').invalid && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)">
                        <label *ngIf="registerForm.get('firstName').errors.required" class="error">This field is
                            required.</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div [class.has-error]="registerForm.get('lastName').invalid && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="lastName">Last Name</label>
                        <input class="form-control" formControlName="lastName" id="lastName" placeholder="Smith"
                               type="text">
                    </div>
                    <div
                            *ngIf="registerForm.get('lastName').invalid && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched)">
                        <label *ngIf="registerForm.get('lastName').errors.required" class="error">This field is
                            required.</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div [class.has-error]="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="email">Email</label>
                        <input class="form-control" formControlName="email" id="email" placeholder="Your email address"
                               type="email">
                    </div>
                    <div
                            *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)">
                        <label *ngIf="registerForm.get('email').errors?.required" class="error">This field is
                            required.</label>
                        <label *ngIf="registerForm.get('email').errors?.pattern" class="error">Enter a valid email.</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div [class.has-error]="registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="password">Password</label>
                        <input class="form-control" formControlName="password" id="password"
                               placeholder="Create a new password"
                               type="password">
                    </div>
                    <div
                            *ngIf="registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched)">
                        <label *ngIf="registerForm.get('password').errors.required" class="error">This field is
                            required.</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div [class.has-error]="registerForm.get('confirmPassword').invalid && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="confirmPassword">Confirm Password</label>
                        <input class="form-control" formControlName="confirmPassword" id="confirmPassword"
                               placeholder="Confirm your password"
                               type="password">
                    </div>
                    <div
                            *ngIf="registerForm.get('confirmPassword').invalid && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)">
                        <label *ngIf="registerForm.get('confirmPassword').errors.required" class="error">This field is
                            required.</label>
                    </div>
                </div>
                <label *ngIf="registerForm.get('password').errors?.pattern" class="error">Use 8 or more characters with a mix of letters, numbers & symbols .</label>
                <label *ngIf="!registerForm.get('password').errors && registerForm.get('confirmPassword').errors?.ConfirmPassword" class="error">Both passwords should be same.</label>
            </div>

            <div class="row m-t-10">
                <div class="col-lg-12">
                    <p><small>I agree to the <a class="text-info" href="javascript:void(0)">DataGRO Terms</a> and <a
                            class="text-info" href="javascript:void(0)">Privacy</a>.</small></p>
                </div>
            </div>
            <button [class.disabled]="registerForm.invalid" [disabled]="registerForm.invalid"
                    class="btn btn-primary btn-cons"
                    type="submit">Create a new account
            </button>
        </form>

    </div>

    <div class="form-note"> Already a member? <a routerLink="/auth/login">Login</a></div>
</div>

