import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Login, User, UserLoaded } from '../../../@auth';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Subject } from 'rxjs';
import { ErrorResponse } from '../../../interfaces/error-response.interface';
import { CommonService } from '../../../services/common.service';
import { AuthNoticeService } from '../../notice/auth-notice.service';

const EMAIL_REGEX = /^[a-zA-Z]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,}$/;

@Component({
    selector: 'dg-login',
    templateUrl: './login.component.html',
    styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnInit, OnDestroy {
    public loginForm: FormGroup;
    public loading = false;
    public verifyEmail = false;
    public errors: string | string[];
    private returnUrl: string;
    private mode: string;
    private unsubscribe: Subject<any>;
    private user: User;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<AppState>,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private auth: AuthService,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private commonService: CommonService,
        private authNoticeService: AuthNoticeService
    ) {
        this.unsubscribe = new Subject();
    }

    submit(): void {
        /** check form */
        if ( this.loginForm.invalid ) {
            return;
        }

        this.loading = true;
        this.authNoticeService.setNotice('', 'danger', 'login');

        const authData = {
            email: this.getFormValue('email'),
            password: this.getFormValue('password')
        };
        this.auth
            .login(authData.email, authData.password)
            .pipe(
                tap((response: any) => {
                        if ( response.data ) {
                            const user = response.data as any;
                            this.store.dispatch(new Login({ authToken: user.token }));
                            console.log(this.returnUrl);
                            this.router.navigateByUrl('/user/dashboard').catch(e => console.log(e)); // Main page
                        } else {
                            this.authNoticeService.setNotice('Invalid user details', 'danger', 'login');
                        }
                    },
                    (err: HttpErrorResponse) => {
                        this.commonService.catchErrors(err, 'login');
                        if ( err.status === 424 || err.status === 412 ) {
                            this.verifyEmail = true;
                            console.log(err);
                            this.user = (err.error as any).data;
                        } else if (err.status == 400){
                          if(err.error.detail){
                            this.authNoticeService.setNotice(err.error.detail, 'danger', 'login');
                          }else{
                            for ( const errorsKey in err ) {
                              if ( err.hasOwnProperty(errorsKey) ) {
                                  this.authNoticeService.setNotice(err[errorsKey], 'danger', 'login');
                              }
                          }

                          }


                        }
                    }),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.loginForm = this.createForm();

        this.route.queryParams.subscribe(params => {
            this.returnUrl = params.returnUrl || '/';
            this.mode = params.mode || '/';
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resendVerificationEmail(): void {
        this.authNoticeService.setNotice('', 'danger', 'login');
        this.errors = '';
        this.verifyEmail = false;
        this.auth.resendEmailVerification({ userId: this.user.userId })
            .subscribe((
                (response) => {
                    this.authNoticeService.setNotice('Email sent successfully', 'info', 'login');
                    // this.successMessage = 'Email sent successfully';
                }
            ), (err: HttpErrorResponse) => {
                const error = err.error as ErrorResponse;
                if ( err.status === 422 ) {
                    this.errors = this.commonService.process422Errors(error.errors);
                } else if ( err.status === 500 ) {
                    this.errors = 'Technical error, please try after sometime.';
                    this.authNoticeService.setNotice(this.errors, 'danger', 'login');
                } else if ( err.status === 400 ) {
                    this.errors = error.message;
                    this.authNoticeService.setNotice(this.errors, 'danger', 'login');
                }
            });
    }

    private getFormValue(key: string): any {
        return this.loginForm.get(key).value;
    }

    private createForm(): FormGroup {
        return this.formBuilder.group({
            email: [
                '', Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.minLength(3),
                    // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                    Validators.maxLength(320),
                    Validators.pattern(EMAIL_REGEX)
                ])
            ],
            password: [
                '', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ]
        });
    }


}
