export class Link {
    first: string;
    last: string;
    next: string;
    prev: string;

    clear(): void {
        this.first = null;
        this.last = null;
        this.next = null;
        this.prev = null;
    }
}

export class Meta {
    from: number;
    path: string;
    to: number;
    total: number;
    // tslint:disable-next-line:variable-name
    current_page: number;
    // tslint:disable-next-line:variable-name
    last_page: number;
    // tslint:disable-next-line:variable-name
    per_page: number;

    constructor(perPage?: number, currentPage?: number) {
        this.per_page = perPage ? perPage : 10;
        this.current_page = currentPage ? currentPage : 0;
    }
}
