import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectsState } from '../_reducers/project.reducers';

import * as fromRouter from '@ngrx/router-store';

export const selectProjectState = createFeatureSelector<ProjectsState>('projects');

export interface State {
    router: fromRouter.RouterReducerState<any>;
}

const selectRouter = createFeatureSelector<State,
    fromRouter.RouterReducerState<any>>('router');

export const {
    selectCurrentRoute,   // select the current route
    selectQueryParams,    // select the current route query params
    selectQueryParam,     // factory function to select a query param
    selectRouteParams,    // select the current route params
    selectRouteParam,     // factory function to select a route param
    selectRouteData,      // select the current route data
    selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectAllProjectsEntities = createSelector(
    selectProjectState,
    project => project.entities
);

export const selectAllProjects = createSelector(
    selectAllProjectsEntities,
    (projects) => Object.keys(projects).map(projectId => projects[projectId])
);

export const selectProjectId = selectRouteParam('projectId');

export const selectProject = createSelector(
    selectAllProjectsEntities,
    selectProjectId,
    (projects, projectsId) => projects[projectsId]
);

export const selectLinks = createSelector(
    selectProjectState,
    (projects) => projects.links
);

export const selectMeta = createSelector(
    selectProjectState,
    (projects) => projects.meta
);


