import { Component } from '@angular/core';
import { fadeAnimation } from './animations/fade-in.animation';

@Component({
    selector: 'dg-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [ fadeAnimation ]
})
export class AppComponent {

    constructor() {
    }


    public getRouterOutletState(outlet): void {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }
}
