import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import { CoreServiceState } from '../_reducers/core-service.reducers';

export const selectCoreServiceState = createFeatureSelector<CoreServiceState>('coreServices');

export interface State {
    router: fromRouter.RouterReducerState<any>;
}

const selectRouter = createFeatureSelector<State,
    fromRouter.RouterReducerState<any>>('router');

export const {
    selectRouteParam,     // factory function to select a route param
} = fromRouter.getSelectors(selectRouter);

export const selectAllCoreServiceEntities = createSelector(
    selectCoreServiceState,
    coreService => coreService.entities
);

export const selectAllCoreService = createSelector(
    selectAllCoreServiceEntities,
    (coreService) => Object.keys(coreService).map(coreServiceId => coreService[coreServiceId])
);

export const selectCoreServiceId = selectRouteParam('coreServiceId');

export const selectCoreService = createSelector(
    selectAllCoreServiceEntities,
    selectCoreServiceId,
    (coreService, coreServiceId) => coreService[coreServiceId]
);

export const selectLinks = createSelector(
    selectCoreServiceState,
    (coreService) => coreService.links
);

export const selectMeta = createSelector(
    selectCoreServiceState,
    (coreService) => coreService.meta
);


