import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthNotice } from './auth-notice.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthNoticeService {
    onNoticeChanged$: BehaviorSubject<AuthNotice>;

    constructor() {
        this.onNoticeChanged$ = new BehaviorSubject(null);
    }

    setNotice(message: string, type?: string, noticeBoardId?: string, timeout?: number): void {
        const notice: AuthNotice = {
            message,
            type,
            noticeBoardId
        };
        this.onNoticeChanged$.next(notice);
        if ( timeout ) {
          setTimeout(() => {
            this.onNoticeChanged$.next(null);
          }, timeout);
        }
    }
}
