import { Action } from '@ngrx/store';
import { Link, Meta } from '../_models/pagination.model';
import { CoreService } from '../_models/core-service.model';

export enum CoreServiceActionTypes {
    AllCoreServiceRequested = '[CoreService API] All CoreService Requested',
    AllCoreServiceLoaded = '[CoreService API] All CoreService Loaded',
    AllCoreServiceLoadFailed = '[CoreService API] All CoreService Load Failed',
}

export class AllCoreServiceRequested implements Action {
    readonly type = CoreServiceActionTypes.AllCoreServiceRequested;
}

export class AllCoreServiceLoaded implements Action {
    readonly type = CoreServiceActionTypes.AllCoreServiceLoaded;

    constructor(public payload: { data: CoreService[], meta: Meta, links: Link }) {
    }
}

export class AllCoreServiceLoadFailed implements Action {
    readonly type = CoreServiceActionTypes.AllCoreServiceLoadFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export type CoreServiceActions = AllCoreServiceRequested
    | AllCoreServiceLoaded
    | AllCoreServiceLoadFailed;
