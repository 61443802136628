<div class="main full-height sm-p-t-30 d-flex flex-column w-100">
    <div class="d-flex flex-column full-height form-container w-100">

        <dg-logo></dg-logo>
        <p>
            Sign into your datagro account.
        </p>
        <div #alertNotice *ngIf="verifyEmail" class="alert alert-danger" role="alert" style="margin: 0.5rem 0">
            <div class="alert-text">
                <p>Please verify your email to login. If you haven't received any mail yet, then feel free to resend it
                    <strong (click)="resendVerificationEmail()" style="cursor: pointer">here</strong></p>
            </div>
        </div>

        <dg-auth-notice noticeBoardId="login"></dg-auth-notice>

        <form (submit)="submit()" [formGroup]="loginForm" class="p-t-15" role="form">

            <div class="row">
                <div class="col-md-12">
                    <div [class.has-error]="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="email">Email</label>
                        <input class="form-control" formControlName="email" id="email" placeholder="Your email"
                               type="email">
                    </div>
                    <div
                            *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                        <label *ngIf="loginForm.get('email').errors?.required" class="error">This field is
                            required.</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div [class.has-error]="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="password">Password</label>
                        <input class="form-control" formControlName="password" id="password" placeholder="Your password"
                               type="password">
                    </div>
                    <div
                            *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                        <label *ngIf="loginForm.get('password').errors.required" class="error">This field is
                            required.</label>
                    </div>
                </div>
            </div>

            <div class="row m-t-10">
                <div class="col-lg-6">
                    <button [class.disabled]="loginForm.invalid" [disabled]="loginForm.invalid"
                            class="btn btn-primary btn-cons"
                            type="submit">Login
                    </button>
                </div>
                <div class="col-lg-6 text-right">
                    <a class="text-info small" routerLink="/auth/forgot-password">Forgot Password?</a>
                </div>
            </div>
        </form>

    </div>

    <div class="form-note"> Not a member? <a routerLink="/auth/register">Sign up now</a></div>
</div>
