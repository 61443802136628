import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dg-logo',
    templateUrl: './logo.component.html',
    styleUrls: [ './logo.component.scss' ]
})
export class LogoComponent implements OnInit {
    @Input() origin: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
