// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
    // intercept request and add token
    constructor(
        private router: Router
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // tslint:disable-next-line:no-debugger
        // modify request
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${ localStorage.getItem(environment.authTokenKey) }`

            }
        });
        // console.log('----request----');
        // console.log(request);
        // console.log('--- end of request---');

        return next.handle(request).pipe(
            tap(
                event => {
                    if ( event instanceof HttpResponse ) {
                        // console.log('all looks good');
                        // http response status code
                        // console.log(event.status);
                    }
                },
                error => {
                    // http response status code
                    // console.log('----response----');
                    // console.error('status code:');
                    // tslint:disable-next-line:no-debugger
                    if ( error.status === 401 || error.status === 403) {
                        localStorage.clear();
                        this.router.navigate([
                            '/auth/login'
                        ]);
                    }
                    // console.log('--- end of response---');
                }
            )
        );
    }
}
