import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { Register, User } from '../../../@auth';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';
import { Subject } from 'rxjs';
import { ErrorResponse } from '../../../interfaces/error-response.interface';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { AuthNoticeService } from '../../notice/auth-notice.service';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';

const EMAIL_REGEX = /^[a-zA-Z]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,}$/;
const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[_!@#$%^&'])[^ ]{6,}$/;

@Component({
    selector: 'dg-register',
    templateUrl: './register.component.html',
    styleUrls: [ './register.component.scss' ]
})
export class RegisterComponent implements OnInit, OnDestroy {
    public registerForm: FormGroup;
    public loading = false;

    errors: any = [];
    returnUrl = '/';

    private unsubscribe: Subject<any>;


    constructor(
        private formBuilder: FormBuilder,
        private auth: AuthService,
        private cdr: ChangeDetectorRef,
        private store: Store<AppState>,
        private authNoticeService: AuthNoticeService,
        private commonService: CommonService,
        private router: Router
    ) {
        this.unsubscribe = new Subject<any>();
    }

    public submit(): void {
        this.loading = true;

        if ( !this.registerForm.get('agree').value ) {
            this.authNoticeService.setNotice('You must agree the terms and condition', 'danger', 'register');
            this.loading = false;
            return;
        }

        const user: User = new User();
        user.clear();
        user.email = this.getFormValue('email');
        user.name = `${ this.getFormValue('firstName') } ${ this.getFormValue('lastName') }`;
        user.password = this.getFormValue('password');
        user.password_confirmation = this.getFormValue('confirmPassword');

        console.log(user);
        // @ngrx/effects @ngrx/entity @ngrx/router-store @ngrx/store @ngrx/store-devtools ngrx-store-freeze
        this.auth.register(user).pipe(
            tap(response => {
                    if ( response ) {
                        //this.store.dispatch(new Register({ authToken: user.token }));
                        this.authNoticeService
                            .setNotice('Registration Success. Please verify your email address.', 'success', 'register', 5000);
                        setTimeout(() => {
                            this.router.navigateByUrl('/auth/login');
                        }, 5000);
                    } else {
                        this.authNoticeService.setNotice('INVALID LOGIN', 'danger', 'register');
                    }
                },
                (err: HttpErrorResponse) => {
                    const error = err.error as ErrorResponse;
                    if ( err.status === 422 ) {
                        this.errors = this.commonService.process422Errors(error.errors);
                        for ( const errorsKey in this.errors ) {
                            if ( this.errors.hasOwnProperty(errorsKey) ) {
                                this.authNoticeService.setNotice(this.errors, 'danger', 'register');
                            }
                        }
                    } else if ( err.status === 500 ) {
                        this.errors = 'Technical error, please try after sometime.';
                        this.authNoticeService.setNotice(this.errors, 'danger', 'register');
                    } else if ( err.status === 400 ) {
                      for ( const errorsKey in error ) {
                        if ( error.hasOwnProperty(errorsKey) ) {
                            this.authNoticeService.setNotice(error[errorsKey], 'danger', 'register');
                        }
                        break;
                      }

                    }else {
                      for ( const errorsKey in this.errors ) {
                        if ( this.errors.hasOwnProperty(errorsKey) ) {
                            this.authNoticeService.setNotice(this.errors, 'danger', 'register');
                        }
                    }
                    }
                }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.loading = false;
                this.cdr.markForCheck();
            })
        ).subscribe();
    }

    ngOnInit(): void {
        this.registerForm = this.createForm();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private getFormValue(key: string): any {
        return this.registerForm.get(key).value;
    }

    private createForm(): FormGroup {
        return this.formBuilder.group({
            firstName: [ '', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]) ],
            lastName: [ '', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]) ],
            email: [ '', Validators.compose([
                Validators.required,
                Validators.email,
                Validators.minLength(3),
                // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                Validators.maxLength(320),
                Validators.pattern(EMAIL_REGEX)
            ]) ],
            password: [ '', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100),
                Validators.pattern(PASSWORD_REGEX)
            ]) ],
            confirmPassword: [ '', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100),
                Validators.pattern(PASSWORD_REGEX)
            ]) ],
            agree: [ true, Validators.compose([ Validators.required ]) ]
        }, {
            validator: ConfirmPasswordValidator.MatchPassword
        });
    }
}
