import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { AuthNoticeService } from '../../notice/auth-notice.service';
import { Subject } from 'rxjs';
import { ErrorResponse } from '../../../interfaces/error-response.interface';
import { CommonService } from '../../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';

const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[_!@#$%^&'])[^ ]{6,}$/;

@Component({
    selector: 'dg-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: [ './reset-password.component.scss' ]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    public resetPassword: FormGroup;
    public loading = false;
    private unsubscribe: Subject<any>;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private authNoticeService: AuthNoticeService,
        private commonService: CommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.unsubscribe = new Subject<any>();
    }

    createForm(email: string, token: string): FormGroup {
        return this.formBuilder.group({
            email: [ email, Validators.compose([
                Validators.required
            ]) ],
            token: [ token, Validators.compose([
                Validators.required
            ]) ],
            password: [ '', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100),
                Validators.pattern(PASSWORD_REGEX)
            ]) ],
            password_confirmation: [ '', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100),
                Validators.pattern(PASSWORD_REGEX)
            ]) ],
        });
    }

    submit(): void {
        const controls = this.resetPassword.controls;
        /** check form */
        if ( this.resetPassword.invalid ) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.loading = true;

        const email = controls.email.value;
        this.authService.resetPasswordCode(this.resetPassword.value).pipe(
            tap(response => {
                if ( response ) {
                    this.authNoticeService.setNotice('Password changed successfully.', 'success', 'reset-password');
                    localStorage.clear();
                    this.router.navigateByUrl('/auth/login');
                } else {
                    this.authNoticeService.setNotice(`The requested ${ email } is not found`, 'danger', 'reset-password');
                }
            }, (err) => {
                const error = err.error as ErrorResponse;
                let errors: any;
                if ( err.status === 422 ) {
                    errors = this.commonService.process422Errors(error.errors);
                    for ( const errorsKey in errors ) {
                        if ( errors.hasOwnProperty(errorsKey) ) {
                            this.authNoticeService.setNotice(errors[errorsKey], 'danger', 'reset-password');
                        }
                    }
                } else if ( err.status === 500 ) {
                    errors = 'Technical error, please try after sometime.';
                    this.authNoticeService.setNotice(errors, 'danger', 'reset-password');
                } else if ( err.status === 400 ) {
                    errors = error.message;
                    this.authNoticeService.setNotice(errors, 'danger', 'reset-password');
                } else if ( err.status === 412 ) {
                    errors = error;
                    this.authNoticeService.setNotice(errors, 'danger', 'reset-password');
                }
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.loading = false;
                this.resetPassword.reset();
                this.cdr.markForCheck();
            })
        ).subscribe();
    }

    ngOnInit(): void {
        const email = this.activatedRoute.snapshot.queryParamMap.get('email');
        const token = this.activatedRoute.snapshot.queryParamMap.get('token');
        this.resetPassword = this.createForm(email, token);
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
