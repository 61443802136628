import { Action } from '@ngrx/store';
import { Notification } from '../_models/notification.model';
import { Link, Meta } from '../_models/pagination.model';

export enum NotificationActionTypes {
    AllNotificationsRequested = '[Notifications API] All Notifications Requested',
    AllNotificationsLoaded = '[Notifications API] All Notifications Loaded',
    AllNotificationsLoadFailed = '[Notifications API] All Notifications Load Failed',
    NotificationSocketReceived = '[Notifications API] Notification Socket Received',
    NotificationSocketUpdate = '[Notifications API] Notification Socket Update',

    NotificationMarkAsReadRequested = '[Notification API] Notification Mark As Read Requested',
    NotificationMarkAsReadSuccess = '[Notification API] Notification Mark As Read Success',
    NotificationMarkAsReadFailed = '[Notification API] Notification Mark As Read Failed',

    AllNotificationMarkAsReadRequested = '[Notification API] All Notification Mark As Read Requested',
    AllNotificationMarkAsReadSuccess = '[Notification API] All Notification Mark As Read Success',
    AllNotificationMarkAsReadFailed = '[Notification API] All Notification Mark As Read Failed',

    ShowDetailedNotification = '[Notification API] Show Detailed Notification',
}

export class AllNotificationsRequested implements Action {
    readonly type = NotificationActionTypes.AllNotificationsRequested;

    constructor(public payload: { perPage: number, currentPage: number }) {
    }
}

export class AllNotificationsLoaded implements Action {
    readonly type = NotificationActionTypes.AllNotificationsLoaded;

    constructor(public payload: { data: Notification[], meta: Meta, links: Link }) {
    }
}

export class AllNotificationsLoadFailed implements Action {
    readonly type = NotificationActionTypes.AllNotificationsLoadFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class NotificationSocketReceived implements Action {
    readonly type = NotificationActionTypes.NotificationSocketReceived;

    constructor(public payload: { notification: Notification }) {
    }
}

export class NotificationMarkAsReadRequested implements Action {
    readonly type = NotificationActionTypes.NotificationMarkAsReadRequested;

    constructor(public payload: { notification: Notification }) {
    }
}

export class NotificationMarkAsReadSuccess implements Action {
    readonly type = NotificationActionTypes.NotificationMarkAsReadSuccess;

    constructor(public payload: { notification: Notification }) {
    }
}

export class NotificationMarkAsReadFailed implements Action {
    readonly type = NotificationActionTypes.NotificationMarkAsReadFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class AllNotificationMarkAsReadRequested implements Action {
    readonly type = NotificationActionTypes.AllNotificationMarkAsReadRequested;

    constructor(public payload: { notifications: Notification[] }) {
    }
}

export class AllNotificationMarkAsReadSuccess implements Action {
    readonly type = NotificationActionTypes.AllNotificationMarkAsReadSuccess;

    constructor(public payload: { notifications: Notification[] }) {
    }
}

export class AllNotificationMarkAsReadFailed implements Action {
    readonly type = NotificationActionTypes.AllNotificationMarkAsReadFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class ShowDetailedNotification implements Action {
    readonly type = NotificationActionTypes.ShowDetailedNotification;

    constructor(public payload: { notification: Notification }) {
    }
}

export class NotificationSocketUpdate implements Action {
    readonly type = NotificationActionTypes.NotificationSocketUpdate;
}
export type NotificationActions = AllNotificationsRequested
    | AllNotificationsLoaded
    | AllNotificationsLoadFailed
    | NotificationSocketReceived
    | NotificationMarkAsReadRequested
    | NotificationMarkAsReadSuccess
    | NotificationMarkAsReadFailed
    | AllNotificationMarkAsReadRequested
    | AllNotificationMarkAsReadSuccess
    | AllNotificationMarkAsReadFailed
    | ShowDetailedNotification;
