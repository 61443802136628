import { Link, Meta } from './pagination.model';
import { Project } from './project.model';
import { CoreService } from './core-service.model';

export class ServiceModel {
    data: Service[];
    meta: Meta;
    links: Link;
}

export class Service {
    estimation: number;
    id: string;
    input: string;
    output: string;
    project: Project;
    service: CoreService;
    state: 'initiated' | 'processing' | 'completed' | 'stopped' | 'cancelled';
    stats: ServiceStats;
    name: string;
    updatedAt: string;
    createdAt: string;
    isSocketConnected: boolean;
    disable_service:boolean
}

export class ServiceStats {
    total: number;
    processed: number;
    failed: number;
}
