import { createFeatureSelector } from '@ngrx/store';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Service } from '../_models/service.model';
import { ServiceActions, ServiceActionTypes } from '../_actions/service.actions';
import { Link, Meta } from '../_models/pagination.model';

export interface ServicesState extends EntityState<Service> {
    meta: Meta;
    links: Link;
    loading: boolean;
}

export const adapter: EntityAdapter<Service> = createEntityAdapter<Service>();

export const initialServicesState: ServicesState = adapter.getInitialState({
    meta: new Meta(),
    links: new Link(),
    loading: false
});

export function servicesReducer(state: ServicesState = initialServicesState, action: ServiceActions): ServicesState {
    switch ( action.type ) {
        case ServiceActionTypes.AllServicesRequestedForNewProjectID:
            return adapter.getInitialState(initialServicesState);

        case ServiceActionTypes.AllServicesRequested:
            return {
                ...state,
                loading: true
            };

        case ServiceActionTypes.AllServicesLoadFailed:
            return {
                ...state,
                loading: false
            };

        case ServiceActionTypes.AllServicesLoaded:
            return adapter.addMany(action.payload.data, {
                ...state,
                meta: action.payload.meta,
                links: action.payload.links,
                loading: false
            });

        case ServiceActionTypes.CreateNewService:
            return {
                ...state,
                loading: true,
            };

        case ServiceActionTypes.NewServiceCreationSuccess:
            return adapter.addOne(action.payload.service, {
                ...state,
                loading: false,
            });

        case ServiceActionTypes.NewServiceCreationFailed:
            return {
                ...state,
                loading: false,
            };

        case ServiceActionTypes.StartService:
            return {
                ...state,
                loading: true,
            };

        case ServiceActionTypes.StartServiceSuccess:
            return adapter.upsertOne(action.payload.service, {
                ...state,
                loading: false,
            });

        case ServiceActionTypes.StartServiceFailed:
            return {
                ...state,
                loading: false,
            };

        case ServiceActionTypes.StopService:
            return {
                ...state,
                loading: true,
            };

        case ServiceActionTypes.StopServiceSuccess:
            return adapter.upsertOne(action.payload.service, {
                ...state,
                loading: false,
            });

        case ServiceActionTypes.StopServiceFailed:
            return {
                ...state,
                loading: false,
            };
        case ServiceActionTypes.CancelService:
            return {
                ...state,
                loading: true,
            };

        case ServiceActionTypes.CancelServiceSuccess:
            return adapter.upsertOne(action.payload.service, {
                ...state,
                loading: false,
            });

        case ServiceActionTypes.CancelServiceFailed:
            return {
                ...state,
                loading: false,
            };
        case ServiceActionTypes.DeleteService:
            return {
                ...state,
                loading: true,
            };

        case ServiceActionTypes.DeleteServiceSuccess:
            return adapter.removeOne(action.payload.service.id, {
                ...state,
                meta: {
                    from: state.meta.from,
                    path: state.meta.path,
                    to: state.meta.to - 1,
                    total: state.meta.total - 1,
                    current_page: state.meta.current_page,
                    last_page: state.meta.last_page,
                    per_page: state.meta.per_page,
                },
                links: state.links,
                loading: false,
            });

        case ServiceActionTypes.DeleteServiceFailed:
            return {
                ...state,
                loading: false,
            };

        case ServiceActionTypes.ServiceStatsSocketUpdate:
            return adapter.updateOne({ id: action.payload.serviceId, changes: action.payload }, state);

        case ServiceActionTypes.ServiceSocketUpdate:
            return adapter.upsertOne(action.payload.service, {
                ...state,
                loading: false,
            });

        case ServiceActionTypes.ServiceSocketConnectionStatus:
            return adapter.updateMany(
                action.payload.services.map((service: Service) =>
                    Object.assign({}, { id: service.id, changes: { isSocketConnected: service.isSocketConnected } })),
                {
                    ...state,
                    loading: false,
                });

        default:
            return state;
    }
}

export const getServiceState = createFeatureSelector<ServicesState>('services');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
