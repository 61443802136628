import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { User } from '../@auth';
import { environment } from '../../environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const API_USERS_REGISTER = `${ environment.authEndpoint }user/registration/`;
const API_USERS_LOGIN = `${ environment.authEndpoint }user/login/`;
const AUTH_EMAIL_RESEND = `${ environment.authEndpoint }user/email/`;
const API_FORGOT_PASSWORD = `${ environment.authEndpoint }user/password/reset/`;
const API_RESET_PASSWORD = `${ environment.authEndpoint }user/password/reset/`;


@Injectable()
export class AuthService {
    private http: HttpClient;

    constructor(
        private handler: HttpBackend,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this.http = new HttpClient(this.handler);
    }

    public login(email: string, password: string): Observable<User> {
        return this.http.post<User>(`${ API_USERS_LOGIN }`, { email, password });
    }


    register(user: User): Observable<any> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<any>(`${ API_USERS_REGISTER }`, user, { headers: httpHeaders })
            .pipe(
                map((res: any) => {
                    console.log(res);
                    return res;
                })
            );
    }

    /**
     * Submit forgot password request
     *
     * @param email: {string}
     * @returns Observable<any>
     */
    public requestPassword(email: string): Observable<any> {
        return this.http.post(`${ API_FORGOT_PASSWORD }`, { email })
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    resendEmailVerification(data: any): Observable<any> {
        return this.http.get(`${ AUTH_EMAIL_RESEND }/resend/${ data.userId }`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    checkEmailVerificationCode(apiUrl): Observable<any> {
        return this.http.get(`${ apiUrl }`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    resetPasswordCode(data: any): Observable<any> {
        return this.http.post(`${ API_RESET_PASSWORD }`, data)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

}
