import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SwUpdate, UpdateActivatedEvent, UpdateAvailableEvent } from '@angular/service-worker';
import { BehaviorSubject, merge, fromEvent, of, interval } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
import { NotificationService } from '../modules/layout/components/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AppStatusService {

  swUpdates = new BehaviorSubject('');

  // update & reload the application when new assets have been download
  public reloadApp(): any {
    /**
     * Calls post message to the Service Workers inner scope (ACTIVATE_UPDATE)
     * https://developer.mozilla.org/en-US/docs/Web/API/Worker/postMessage
     */
    if (isPlatformBrowser(this.platformId)) {
      if (environment.production && 'serviceWorker' in navigator) {
        return this.updates.activateUpdate().then(() => document.location.reload());
      }
    }
  }

  // an observable of available service worker updates
  get updateAvailable() {
    return this.swUpdates.asObservable();
  }

  // the online status of the application
  get online() {
    return merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
  }

  constructor(
    private updates: SwUpdate,
    @Inject(PLATFORM_ID) private platformId,
    private notification: NotificationService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (environment.production && 'serviceWorker' in navigator) {
        // programmatically check for updates every 5 seconds
        interval(10000 * 6).subscribe(() => this.updates.checkForUpdate());
      }

      // Notify the service when a new service worker version is available
      this.updates.available.subscribe((event: UpdateAvailableEvent) => {
        console.log('version is available [ Available ] current version: ', event.current);
        console.log('version is available [ Available ] available version: ', event.available);

        if (event.available) {
          // notify subscribers of a new app version
          this.swUpdates.next(event.available.hash.slice(0, 4));

          this.notification.create('info', 'New version available', {
            Position: 'bottom-right',
            Style: 'bar',
            Duration: 0,
            Action: 'reload'
          });
        }
      });

      // Notify the service when service worker starts serving a new version of the application immediately
      this.updates.activated.subscribe((event: UpdateActivatedEvent) => {
        console.log('starts serving [ Activated ] old version was: ', event.previous);
        console.log('starts serving [ Activated ] new version is: ', event.current);
      });
    }
  }
}
