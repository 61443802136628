import { createFeatureSelector } from '@ngrx/store';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Project } from '../_models/project.model';
import { ProjectActions, ProjectActionTypes } from '../_actions/project.actions';
import { Link, Meta } from '../_models/pagination.model';

export interface ProjectsState extends EntityState<Project> {
    meta: Meta;
    links: Link;
    loading: boolean;
}

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>();

export const initialProjectsState: ProjectsState = adapter.getInitialState({
    meta: new Meta(),
    links: new Link(),
    loading: false
});

export function projectsReducer(state: ProjectsState = initialProjectsState, action: ProjectActions): ProjectsState {
    switch ( action.type ) {
        case ProjectActionTypes.AllProjectsRequested:
            return adapter.getInitialState(initialProjectsState);

        case ProjectActionTypes.AllProjectsLoadFailed:
            return {
                ...state,
                loading: false
            };

        case ProjectActionTypes.AllProjectsLoaded:
            return adapter.addMany(action.payload.data, {
                ...state,
                meta: action.payload.meta,
                links: action.payload.links,
                loading: false
            });

        case ProjectActionTypes.CreateNewProject:
            return {
                ...state,
                meta: {
                    from: state.meta.from,
                    path: state.meta.path,
                    to: state.meta.to + 1,
                    total: state.meta.total + 1,
                    current_page: state.meta.current_page,
                    last_page: state.meta.last_page,
                    per_page: state.meta.per_page,
                },
                links: state.links,
                loading: true,
            };

        case ProjectActionTypes.NewProjectCreationSuccess:
            return adapter.addOne(action.payload.project, {
                ...state,
                loading: false,
            });

        case ProjectActionTypes.NewProjectCreationFailed:
            return {
                ...state,
                loading: false,
            };

        case ProjectActionTypes.DeleteProject:
            return {
                ...state,
                loading: true,
            };

        case ProjectActionTypes.ProjectDeleteSuccess:
            return adapter.removeOne(action.payload.project.id, {
                ...state,
                meta: {
                    from: state.meta.from,
                    path: state.meta.path,
                    to: state.meta.to - 1,
                    total: state.meta.total - 1,
                    current_page: state.meta.current_page,
                    last_page: state.meta.last_page,
                    per_page: state.meta.per_page,
                },
                links: state.links,
                loading: false,
            });

        case ProjectActionTypes.ProjectDeleteFailed:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}

export const getProjectState = createFeatureSelector<ProjectsState>('projects');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
