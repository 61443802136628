import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationsState, sortLatest } from '../_reducers/notification.reducers';

import * as fromRouter from '@ngrx/router-store';
import { Dictionary } from '@ngrx/entity';
import { Notification } from '../_models/notification.model';

export const selectNotificationState = createFeatureSelector<NotificationsState>('notification');

export interface State {
    router: fromRouter.RouterReducerState<any>;
}

const selectRouter = createFeatureSelector<State,
    fromRouter.RouterReducerState<any>>('router');

export const {
    selectCurrentRoute,   // select the current route
} = fromRouter.getSelectors(selectRouter);

export const selectAllNotificationsEntities = createSelector(
    selectNotificationState,
    notification => notification.entities
);

export const selectAllNotifications = createSelector(
    selectAllNotificationsEntities,
    (notifications: Dictionary<Notification>) => Object.keys(notifications).map(notificationId => notifications[notificationId])
);

export const selectNewNotifications = createSelector(
    selectAllNotificationsEntities,
    (notifications: Dictionary<Notification>) => {
        return Object.values(notifications).sort((a: Notification, b: Notification) => sortLatest(a, b));
    }
);

export const selectNotificationMeta = createSelector(
    selectNotificationState,
    (notifications: NotificationsState) => notifications.meta
);


