import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from '../../../interfaces/error-response.interface';
import { CommonService } from '../../../services/common.service';

@Component({
    selector: 'dg-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: [ './email-verification.component.scss' ]
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
    public apiUrl: string;
    public expires: string;
    public signature: string;
    public loading = false;
    public errors: string[] | string;
    public successMessage: string[] | string;
    public expiredLink = false;
    private unsubscribe: Subject<any>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private commonService: CommonService
    ) {
        this.unsubscribe = new Subject<any>();
    }

    ngOnInit(): void {
        this.apiUrl = this.activatedRoute.snapshot.queryParamMap.get('url');

        this.verifyCode(this.apiUrl);
    }

    verifyCode(apiUrl: string): void {
        this.successMessage = '';
        this.errors = '';
        this.loading = true;

        this.authService.checkEmailVerificationCode(apiUrl)
            .pipe(tap(
                (() => {
                    this.loading = false;
                    this.successMessage = 'Your email is successfully verified.';
                }), (err: HttpErrorResponse) => {
                    this.loading = false;
                    const error = err.error as ErrorResponse;
                    if ( err.status === 422 ) {
                        if ( err.message === 'invalid_code' ) {
                            this.expiredLink = true;
                            this.errors = this.commonService.process422Errors(error.errors);
                        }
                    } else if ( err.status === 500 ) {
                        this.errors = 'Technical error, please try after sometime.';
                    } else if ( err.status === 400 ) {
                        this.errors = error.message;
                    } else if ( err.status === 403 || err.status === 412 ) {
                        this.expiredLink = true;
                    }
                },
                ),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })/**/)
            .subscribe();
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    }

}
