import { createFeatureSelector } from '@ngrx/store';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Link, Meta } from '../_models/pagination.model';
import { CoreServiceActions, CoreServiceActionTypes } from '../_actions/core-service.actions';
import { CoreService } from '../_models/core-service.model';

export interface CoreServiceState extends EntityState<CoreService> {
    meta: Meta;
    links: Link;
    loading: boolean;
}

export const adapter: EntityAdapter<CoreService> = createEntityAdapter<CoreService>();

export const initialCoreServiceState: CoreServiceState = adapter.getInitialState({
    meta: new Meta(),
    links: new Link(),
    loading: false
});

export function coreServiceReducer(state: CoreServiceState = initialCoreServiceState, action: CoreServiceActions): CoreServiceState {
    switch ( action.type ) {
        case CoreServiceActionTypes.AllCoreServiceRequested:
            return {
                ...state,
                loading: true
            };

        case CoreServiceActionTypes.AllCoreServiceLoadFailed:
            return {
                ...state,
                loading: false
            };

        case CoreServiceActionTypes.AllCoreServiceLoaded:
            return adapter.addMany(action.payload.data, {
                ...state,
                meta: action.payload.meta,
                links: action.payload.links,
                loading: false
            });

        default:
            return state;
    }
}

export const getCoreServiceState = createFeatureSelector<CoreServiceState>('coreServices');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
