import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import Pusher from 'pusher-js';
import { Service } from '../modules/user/store/_models/service.model';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import {
    ServiceSocketConnectionStatus,
    ServiceSocketUpdate,
    ServiceStatsSocketUpdate
} from '../modules/user/store/_actions/service.actions';
import { Subject } from 'rxjs';
import { Project } from '../modules/user/store/_models/project.model';
import { NotificationSocketReceived } from '../modules/user/store/_actions/notification.actions';
import { Notification } from '../modules/user/store/_models/notification.model';
import { isPlatformBrowser } from '@angular/common';
import { UserUpdated } from '../@auth/_actions/auth.actions';
import { User } from '../@auth';

const PUSHER_KEY = environment.pusher.PUSHER_APP_KEY;
const PUSHER_CLUSTER = environment.pusher.PUSHER_APP_CLUSTER;

@Injectable()
export class PusherHandlerService {
    public subject: Subject<any> = new Subject<any>();
    private pusherClient: Pusher;

    constructor(
        private store: Store<AppState>,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        if ( isPlatformBrowser(this.platformId) ) {
            this.pusherClient = new Pusher(PUSHER_KEY, { cluster: PUSHER_CLUSTER });
        }
    }

    bindUserChannel(userId: string): any {
        try {
            const channel = this.pusherClient.subscribe(`${ userId }`);
            channel.bind(`user.updated`, (data: any) => {
                this.store.dispatch(new UserUpdated({ user: data.user as User }));
            });
            return { userId, isSocketConnected: true };
        } catch ( e ) {
            return { userId, isSocketConnected: false };
        }
    }

    bindNotificationChannel(userId: string): any {
        try {
            const channel = this.pusherClient.subscribe(`${ userId }_notification`);
            channel.bind(`notification.created`, (data: any) => {
                (data.notification as Notification).showDetails = false;
                this.store.dispatch(new NotificationSocketReceived({ notification: data.notification as Notification }));
            });
            return { userId, isSocketConnected: true };
        } catch ( e ) {
            return { userId, isSocketConnected: false };
        }
    }

    async bindSingleService(service: Service): Promise<{ id: string; isSocketConnected: boolean; }> {
        try {
            const channel = this.pusherClient.subscribe(service.id);

            channel.bind(`task.completed.${ service.id }`, (data) => {
                this.store.dispatch(new ServiceStatsSocketUpdate({
                    stats: data.stats,
                    serviceId: service.id
                }));
            });
            return { id: service.id, isSocketConnected: true };
        } catch ( e ) {
            console.log(e);
            alert(1);
            return { id: service.id, isSocketConnected: false };
        }
    }

    async bindBulkServices(services: Service[]): Promise<boolean> {
        const finalServices = [];
        for ( const service of services ) {
            finalServices.push(await this.bindSingleService(service));
        }
        this.store.dispatch(new ServiceSocketConnectionStatus({
            services: finalServices
        }));

        return true;
    }

    async unBindSingleService(service: Service): Promise<{ id: string; isSocketConnected: boolean; }> {
        try {
            this.pusherClient.unsubscribe(service.id);
            console.log(`service ${ service.id } un-binded`);
            return { id: service.id, isSocketConnected: false };
        } catch ( e ) {
            return { id: service.id, isSocketConnected: service.isSocketConnected };
        }
    }

    async unbindBulkServices(services: Service[]): Promise<boolean> {
        const finalServices = [];
        for ( const service of services ) {
            finalServices.push(await this.unBindSingleService(service));
        }
        this.store.dispatch(new ServiceSocketConnectionStatus({
            services: finalServices
        }));

        return true;
    }

    bindEachProject(projectId: string, coreServiceId: string): void {
        const channel = this.pusherClient.subscribe(`${ projectId }.${ coreServiceId }`);
        console.log(`project ${ projectId } binded`);

        channel.bind(`service.updated`, (data) => {
            this.store.dispatch(new ServiceSocketUpdate({
                service: data.service
            }));
        });

        channel.bind(`service.created`, (data) => {
            this.store.dispatch(new ServiceSocketUpdate({
                service: data.service
            }));
        });
    }

    unBindEachProject(project: Project): void {
        this.pusherClient.unsubscribe(project.id);
    }
}
