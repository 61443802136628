import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryLayoutComponent } from './primary-layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PageContainerComponent } from './container/page-container.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { MenuIconComponent } from './menu/menu-icon.component';
import { QuickviewComponent } from './quickview/quickview.component';
import { SharedModule } from '../../shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LogoModule } from '../../logo/logo.module';
import { coreServiceReducer } from '../../user/store/_reducers/core-service.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreServiceEffects } from '../../user/store/_effects/core-service.effects';
import { CoreServiceService } from '../../../services/core-service.service';
import { ServiceEffects, servicesReducer } from '../../user/store';
import { ProjectService } from '../../../services/project.service';
import { ServiceService } from '../../../services/service.service';
import { PusherHandlerService } from '../../../services/pusher-handler.service';
import { AvatarModule } from 'ngx-avatar';
import { notificationsReducer } from '../../user/store/_reducers/notification.reducers';
import { NotificationEffects } from '../../user/store/_effects/notification.effects';
import { UserNotificationService } from '../../../services/user-notification.service';
import { TabsetConfig, TabsModule } from 'ngx-bootstrap/tabs';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from '../../../services/intercept.service';


@NgModule({
    declarations: [
        PrimaryLayoutComponent,
        SidebarComponent,
        PageContainerComponent,
        HeaderComponent,
        MenuComponent,
        MenuIconComponent,
        QuickviewComponent,
    ],
    exports: [
        PrimaryLayoutComponent,
        PageContainerComponent
    ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        RouterModule,
        SharedModule,
        BsDropdownModule.forRoot(),

        AvatarModule,
        LogoModule,

        StoreModule.forFeature('coreServices', coreServiceReducer),
        EffectsModule.forFeature([ CoreServiceEffects ]),

        StoreModule.forFeature('notification', notificationsReducer),
        EffectsModule.forFeature([ NotificationEffects ]),

        StoreModule.forFeature('services', servicesReducer),
        EffectsModule.forFeature([ ServiceEffects ]),
        TabsModule
    ],

    providers: [ TabsetConfig, CoreServiceService, ProjectService, ServiceService, PusherHandlerService, UserNotificationService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: InterceptService,
        multi: true
      }]
})
export class PrimaryLayoutModule {
}
