import { Injectable } from '@angular/core';
import { ErrorResponse } from '../interfaces/error-response.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthNoticeService } from '../modules/notice/auth-notice.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(
        private authNoticeService: AuthNoticeService
    ) {
    }

    public process422Errors(errors: any = {}): string[] {
        const errorMessages: string[] = [];
        for (const error in errors) {
            if (errors.hasOwnProperty(error)) {
                errorMessages.push(errors[error][0]);
            }
        }
        return errorMessages;
    }

    public catchErrors(err: HttpErrorResponse, noticeBoardId: string): void {
        const error = err.error as ErrorResponse;
        let errors: string | string[] = [];
        if ( err.status === 422 ) {
            errors = this.process422Errors(error.errors);
            for ( const errorsKey in errors ) {
                if ( errors.hasOwnProperty(errorsKey) ) {
                    this.authNoticeService.setNotice(errors[errorsKey], 'danger', noticeBoardId);
                }
            }
        } else if ( err.status === 500 ) {
            errors = 'Technical error, please try after sometime.';
        } else if ( err.status === 400 ) {
            errors = error.message;
            this.authNoticeService.setNotice(errors, 'danger', noticeBoardId);
        }
    }
}
