import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Type } from '@angular/core';
import { NotificationContainerComponent } from './notification-container.component';
import { MessageData, MessageDataFilled, MessageDataOptions } from './notification.definitions';

// TODO: remove MessageData generic type as it has no contribution in typing
// tslint:disable-next-line:no-shadowed-variable
export class MessageBaseService<ContainerClass extends NotificationContainerComponent, MessageData> {
    protected counter$ = 0; // Id counter for messages
    protected container$: ContainerClass;

    constructor(overlay: Overlay, containerClass: Type<ContainerClass>, private idPrefix$: string = '') {
        // Wait till wrapper gets init parameters
        setTimeout(() => {
            this.container$ = overlay.create().attach(new ComponentPortal(containerClass)).instance;
        }, 100);
    }

    remove(messageId?: string): void {
        if (messageId) {
            this.container$.removeMessage(messageId);
        } else {
            this.container$.removeMessageAll();
        }
    }

    createMessage(message: object, options?: MessageDataOptions): MessageDataFilled {
        // TODO: spread on literal has been disallow on latest proposal
        const resultMessage: MessageDataFilled = {
            ...message,
            ...{
                messageId: this._generateMessageId(),
                options,
                createdAt: new Date()
            }
        };
        this.container$.createMessage(resultMessage);

        return resultMessage;
    }

    protected _generateMessageId(): string {
        return this.idPrefix$ + this.counter$++;
    }
}

@Injectable()
export class NotificationService extends MessageBaseService<NotificationContainerComponent, MessageData> {
    constructor(overlay: Overlay) {
        super(overlay, NotificationContainerComponent, 'message-');
    }

    // Shortcut methods
    success(content: string, options?: MessageDataOptions): MessageDataFilled {
        return this.createMessage({type: 'success', content}, options);
    }

    error(content: string, options?: MessageDataOptions): MessageDataFilled {
        return this.createMessage({type: 'error', content}, options);
    }

    info(content: string, options?: MessageDataOptions): MessageDataFilled {
        return this.createMessage({type: 'info', content}, options);
    }

    warning(content: string, options?: MessageDataOptions): MessageDataFilled {
        return this.createMessage({type: 'warning', content}, options);
    }

    create(type: string, content: string, options?: MessageDataOptions): MessageDataFilled {
        return this.createMessage({type, content}, options);
    }

    public process422Errors(errors: any = {}): string[] {
        const errorMessages: string[] = [];
        for (const error in errors) {
            if (errors.hasOwnProperty(error)) {
                errorMessages.push(errors[error][0]);
                this.create('error', errors[error][0], {
                    Position: 'bottom-right',
                    Style: 'bar',
                    Duration: 2000,
                });
            }
        }
        return errorMessages;
    }
}
