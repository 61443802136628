import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectModel } from '../modules/user/store';
import { ApiResponse } from '../interfaces/api-response.interface';

const API_PRODUCTS_URL = `${ environment.apiEndpoint }project/all`;
const CREATE_PRODUCTS_URL = `${ environment.apiEndpoint }project/create`;
const DELETE_PRODUCTS_URL = `${ environment.apiEndpoint }project/`;

@Injectable()
export class ProjectService {

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
    }

    // CREATE =>  POST: add a new product to the server
    createProject(productName: string): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(CREATE_PRODUCTS_URL, { name: productName });
    }

    // DELETE => delete the product from the server
    deleteProject(productId: string | number): Observable<ProjectModel> {
        const url = `${ DELETE_PRODUCTS_URL }${ productId }/remove`;
        return this.http.delete<ProjectModel>(url);
    }

    // READ => read all product from the server
    getAllProjects(): Observable<ProjectModel> {
        return this.http.get<ProjectModel>(API_PRODUCTS_URL);
    }
}
