<div class="login-wrapper ">
  <!-- START Login Background Pic Wrapper-->
  <div class="bg-pic">
    <!-- START Background Pic-->
    <!--<div class="img-holder"  [ngStyle]="{'background':'url(assets/img/demo/new-york-city-buildings-sunrise-morning-hd-wallpaper.jpg)'}"></div>-->
    <!-- START Background Caption-->
    <div class="bg-caption pull-bottom sm-pull-bottom p-l-20 m-b-20">
      <dg-logo></dg-logo>
      <!--<h2 class="semi-bold text-white">
        Pages make it easy to enjoy what matters the most in the life</h2>

      <p class="small">
        images Displayed are solely for representation purposes only, All work copyright of respective
        owner, otherwise © 2013-2014 REVOX.
      </p>-->
    </div>
    <!-- END Background Caption-->
  </div>
  <!-- END Login Background Pic Wrapper-->
  <!-- START Login Right Container-->
  <div class="login-container bg-white">
    <router-outlet></router-outlet>
  </div>
  <!-- END Login Right Container-->
</div>
