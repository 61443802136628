import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { User } from '../@auth';
import { environment } from '../../environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../interfaces/api-response.interface';

const API_USERS_URL = `${ environment.authEndpoint }user/profile`;
const API_CHANGE_PASSWORD = `${ environment.authEndpoint }user/change-password/`;
const API_USERS_PLANS = `${ environment.authEndpoint }plan/user-plans`;
const API_USER_SERVICE_STATS = `${ environment.authEndpoint }project`;


@Injectable()
export class AuthUserService {

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
    }

    public getUserByToken(): Observable<User> {
        return this.http.get<User>(API_USERS_URL)
            .pipe(
                map((res: any) => {
                    return res.data;
                })
            );
    }

    public getUserPlans(payload: { currentPage: number, perPage: number }): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(
            `${ API_USERS_PLANS }?per_page=${ payload.perPage }&page=${ payload.currentPage }`
        );
    }

    public changePassword(data: any): Observable<ApiResponse> {
        let data_new={
          "old_password":data.oldPassword,
          "new_password1":data.password,
          "new_password2":data.password_confirmation
        }
        return this.http.post<ApiResponse>(`${ API_CHANGE_PASSWORD }`, data_new);
    }

    public getCombinedApi(): Observable<any> {
        const api = [];
        api.push(this.getUserByToken());
        return forkJoin(api);
    }

}
