import { Link, Meta } from './pagination.model';

export class ProjectModel {
    data: Project[];
    meta: Meta;
    links: Link;
}

export class Project {
    id: string;
    name: string;
    createdAt: string;
}
