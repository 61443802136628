import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ToggleService {
    // Search Toggle
    private searchToggle$ = new Subject();
    searchToggle = this.searchToggle$.asObservable();

    // Quickview Toggle
    private quickViewToggle$ = new Subject();
    quickViewToggle = this.quickViewToggle$.asObservable();

    // Sidebar Toggle - Mobile
    private sideBarToggle$ = new Subject() as Subject<boolean>;
    sideBarToggle = this.sideBarToggle$.asObservable();

    // Secondary Sidebar Toggle - Mobile
    private secondarySideBarToggle$ = new Subject() as Subject<any>;
    secondarySideBarToggle = this.secondarySideBarToggle$.asObservable();

    // Horizontal Menu Toggle - Mobile
    private mobileHorizontaMenu$ = new Subject() as Subject<boolean>;
    mobileHorizontaMenu = this.mobileHorizontaMenu$.asObservable();

    // Menu Pin Toggle
    private menuPinToggle$ = new Subject();
    menuPinToggle = this.menuPinToggle$.asObservable();

    // Menu Pin Toggle
    private menuDrawer$ = new Subject() as Subject<string>;
    menuDrawer = this.menuDrawer$.asObservable();

    // Page Wrapper Class
    private pageContainerClass$ = new Subject() as Subject<string>;
    pageContainerClass = this.pageContainerClass$.asObservable();

    // Page Content Class
    private contentClass$ = new Subject() as Subject<string>;
    contentClass = this.contentClass$.asObservable();

    // Header Class
    private headerClass$ = new Subject() as Subject<string>;
    headerClass = this.headerClass$.asObservable();

    // Body Layout Class
    private bodyLayoutClass$ = new Subject() as Subject<string>;
    bodyLayoutClass = this.bodyLayoutClass$.asObservable();

    // App Layout
    private layout$ = new Subject() as Subject<string>;
    Applayout = this.layout$.asObservable();

    // Footer Visiblity
    private footer$ = new Subject() as Subject<boolean>;
    Footer = this.footer$.asObservable();

    // Page Container Hover Event - Used for sidebar
    private pageContainerHover$ = new Subject() as Subject<boolean>;
    pageContainerHover = this.pageContainerHover$.asObservable();

    setContent(className: string): void {
        this.contentClass$.next(className);
    }

    setPageContainer(className: string): void {
        this.pageContainerClass$.next(className);
    }

    setHeaderClass(className: string): void {
        this.headerClass$.next(className);
    }

    setBodyLayoutClass(className: string): void {
        this.bodyLayoutClass$.next(className);
    }

    removeBodyLayoutClass(className: string): void {
        this.bodyLayoutClass$.next(className);
    }

    changeLayout(className: string): void {
        this.layout$.next(className);
    }

    toggleSearch(toggle: boolean): void {
        this.searchToggle$.next({text: toggle});
    }

    toggleMenuPin(toggle: boolean): void {
        this.menuPinToggle$.next({text: toggle});
    }

    toggleMenuDrawer(): void {
        this.menuDrawer$.next();
    }

    toggleQuickView(): void {
        this.quickViewToggle$.next();
    }

    toggleMobileSideBar(toggle: boolean): void {
        this.sideBarToggle$.next(toggle);
    }

    toggleSecondarySideBar(toggle): void {
        this.secondarySideBarToggle$.next(toggle);
    }

    toggleMobileHorizontalMenu(toggle): void {
        this.mobileHorizontaMenu$.next(toggle);
    }

    toggleFooter(toggle: boolean): void {
        this.footer$.next(toggle);
    }

    triggerPageContainerHover(toggle: boolean): void {
        this.pageContainerHover$.next(toggle);
    }
}
