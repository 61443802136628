import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, AccessGuard } from './@auth';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
        canActivate: [AccessGuard],
    },
    {
        path: 'projects',
        loadChildren: () => import('./modules/user/project/project.module').then(m => m.ProjectModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard],
    },

    { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
    {
        path: '',
        redirectTo: '/user/dashboard',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
