<div class="main full-height sm-p-t-30 d-flex flex-column">
    <div class="d-flex flex-column full-height form-container">

        <dg-logo></dg-logo>
        <p>
            Reset your datagro account's password.
            Please enter the email registered with us, to receive an email with further instructions to reset your
            password.
        </p>
        <form (submit)="submit()" [formGroup]="forgotPasswordForm" class="p-t-15" role="form">

            <div class="row">
                <div class="col-md-12">
                    <div [class.has-error]="forgotPasswordForm.get('email').invalid && (forgotPasswordForm.get('email').dirty || forgotPasswordForm.get('email').touched)"
                         class="form-group form-group-default"
                         dgFormGroupDefault>
                        <label for="email">Email</label>
                        <input class="form-control" formControlName="email" id="email" placeholder="Your email"
                               type="email">
                    </div>
                    <div
                            *ngIf="forgotPasswordForm.get('email').invalid && (forgotPasswordForm.get('email').dirty || forgotPasswordForm.get('email').touched)">
                        <label *ngIf="forgotPasswordForm.get('email').errors?.required" class="error">This field is
                            required.</label>
                    </div>
                </div>
            </div>

            <div class="row m-t-10">
                <div class="col-lg-6">
                    <button [class.disabled]="forgotPasswordForm.invalid || loading"
                            [disabled]="forgotPasswordForm.invalid || loading"
                            class="btn btn-primary btn-cons"
                            type="submit">Forgot Password
                    </button>
                </div>
            </div>
        </form>

    </div>

    <div class="form-note"> Back to <a routerLink="/auth/login">Login.</a></div>
</div>
