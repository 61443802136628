import { Action } from '@ngrx/store';
import { Service, ServiceStats } from '../_models/service.model';
import { Link, Meta } from '../_models/pagination.model';

export enum ServiceActionTypes {
    AllServicesRequestedForNewProjectID = '[Services API] All Services Requested for New Project ID',

    AllServicesRequested = '[Services API] All Services Requested',
    AllServicesLoaded = '[Services API] All Services Loaded',
    AllServicesLoadFailed = '[Services API] All Services Load Failed',

    CreateNewService = '[Services API] New Service Creation Request',
    NewServiceCreationSuccess = '[Services API] New Service Creation Success',
    NewServiceCreationFailed = '[Services API] New Service Creation Failed',

    StartService = '[Services API] Service Start Event',
    StartServiceSuccess = '[Services API] Service Start Success',
    StartServiceFailed = '[Services API] Service Start Failed',

    StopService = '[Services API] Service Stop Event',
    StopServiceSuccess = '[Services API] Service Stop Success',
    StopServiceFailed = '[Services API] Service Stop Failed',

    CancelService = '[Services API] Service Cancel Event',
    CancelServiceSuccess = '[Services API] Service Cancel Success',
    CancelServiceFailed = '[Services API] Service Cancel Failed',

    DeleteService = '[Services API] Service Delete Request',
    DeleteServiceSuccess = '[Services API] Service Delete Success',
    DeleteServiceFailed = '[Services API] Service Delete Failed',

    ServiceSocketUpdate = '[Service Socket Update] Service Update VIA websocket',
    ServiceStatsSocketUpdate = '[Service Socket Update] Service Stats Update VIA websocket',

    ServiceSocketConnectionStatus = '[Service Socket Connection Status] Service Socket Connection Status',
}

export class AllServicesRequestedForNewProjectID implements Action {
    readonly type = ServiceActionTypes.AllServicesRequestedForNewProjectID;

    constructor(public payload: { projectId: string, coreServiceID: string, perPage: number, currentPage: number }) {
    }
}

export class AllServicesRequested implements Action {
    readonly type = ServiceActionTypes.AllServicesRequested;

    constructor(public payload: { projectId: string, coreServiceID: string, perPage: number, currentPage: number }) {
    }
}

export class AllServicesLoaded implements Action {
    readonly type = ServiceActionTypes.AllServicesLoaded;

    constructor(public payload: { data: Service[], meta: Meta, links: Link }) {
    }
}

export class AllServicesLoadFailed implements Action {
    readonly type = ServiceActionTypes.AllServicesLoadFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class CreateNewService implements Action {
    readonly type = ServiceActionTypes.CreateNewService;

    constructor(public payload: { formValue: any, coreServiceId: string, projectId: string }) {
    }
}

export class NewServiceCreationSuccess implements Action {
    readonly type = ServiceActionTypes.NewServiceCreationSuccess;

    constructor(public payload: { service: Service }) {
    }
}

export class NewServiceCreationFailed implements Action {
    readonly type = ServiceActionTypes.NewServiceCreationFailed;

    constructor(public payload: { err: any, caught?: any }) {
    }
}

export class StartService implements Action {
    readonly type = ServiceActionTypes.StartService;

    constructor(public payload: { projectId: string, serviceId: string }) {
    }
}

export class StartServiceSuccess implements Action {
    readonly type = ServiceActionTypes.StartServiceSuccess;

    constructor(public payload: { service: Service }) {
    }
}

export class StartServiceFailed implements Action {
    readonly type = ServiceActionTypes.StartServiceFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class StopService implements Action {
    readonly type = ServiceActionTypes.StopService;

    constructor(public payload: { projectId: string, serviceId: string }) {
    }
}

export class StopServiceSuccess implements Action {
    readonly type = ServiceActionTypes.StopServiceSuccess;

    constructor(public payload: { service: Service }) {
    }
}

export class StopServiceFailed implements Action {
    readonly type = ServiceActionTypes.StopServiceFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class CancelService implements Action {
    readonly type = ServiceActionTypes.CancelService;

    constructor(public payload: { projectId: string, serviceId: string }) {
    }
}

export class CancelServiceSuccess implements Action {
    readonly type = ServiceActionTypes.CancelServiceSuccess;

    constructor(public payload: { service: Service }) {
    }
}

export class CancelServiceFailed implements Action {
    readonly type = ServiceActionTypes.CancelServiceFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class DeleteService implements Action {
    readonly type = ServiceActionTypes.DeleteService;

    constructor(public payload: { service: Service }) {
    }
}

export class DeleteServiceSuccess implements Action {
    readonly type = ServiceActionTypes.DeleteServiceSuccess;

    constructor(public payload: { service: Service }) {
    }
}

export class DeleteServiceFailed implements Action {
    readonly type = ServiceActionTypes.DeleteServiceFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class ServiceStatsSocketUpdate implements Action {
    readonly type = ServiceActionTypes.ServiceStatsSocketUpdate;

    constructor(public payload: { stats: ServiceStats, serviceId: string }) {
    }
}

export class ServiceSocketUpdate implements Action {
    readonly type = ServiceActionTypes.ServiceSocketUpdate;

    constructor(public payload: { service: Service }) {
    }
}

export class ServiceSocketConnectionStatus implements Action {
    readonly type = ServiceActionTypes.ServiceSocketConnectionStatus;

    constructor(public payload: { services: Partial<Service[]> }) {
    }
}

export type ServiceActions = AllServicesRequestedForNewProjectID
    | AllServicesRequested
    | AllServicesLoaded
    | AllServicesLoadFailed
    | CreateNewService
    | NewServiceCreationSuccess
    | NewServiceCreationFailed
    | StartService
    | StartServiceSuccess
    | StartServiceFailed
    | StopService
    | StopServiceSuccess
    | StopServiceFailed
    | CancelService
    | CancelServiceSuccess
    | CancelServiceFailed
    | DeleteService
    | DeleteServiceSuccess
    | DeleteServiceFailed
    | ServiceStatsSocketUpdate
    | ServiceSocketUpdate
    | ServiceSocketConnectionStatus;
