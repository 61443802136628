import { Plan } from '../../modules/user/store/_models/plan.model';

export class User {
    id?: number;
    name?: string;
    password?: string;
    // tslint:disable-next-line:variable-name
    password_confirmation?: string;
    email: string;
    token?: string;

    createdAt?: string;
    userId?: string;
    verifiedAt?: string;
    balance: number;
    plan: UserPlan;
    project: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.password = '';
        this.email = '';
        this.token = '';
        this.password_confirmation = '';
        this.balance = 0;

        this.createdAt = '';
        this.userId = '';
        this.verifiedAt = '';
    }
}

export class UserPlan {
    activeTill: string;
    id: number;
    lastCreditAt: string;
    paidTill: string;
    plan: Plan;
    status: string;
}
