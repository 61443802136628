<div class="main full-height sm-p-t-30 d-flex flex-column w-100">
    <div class="d-flex flex-column full-height form-container w-100">

        <dg-logo></dg-logo>
        <p>
            Email Verification
        </p>
        <dg-auth-notice noticeBoardId="email-verification"></dg-auth-notice>

        <div class="d-flex flex-column-fluid flex-center mt-30">
            <!--begin::Signin-->
            <div class="login-form login-signin w-100 mt-5">
                <div #alertNotice class="alert alert-info w-100" role="alert">
                    <div class="alert-text">
                        <p *ngIf="expiredLink">The link has been expired or you are not authorized to this action.</p>
                        <p *ngIf="loading">We are verifying your identity.</p>
                        <p *ngIf="successMessage">{{ successMessage }}</p>
                    </div>
                </div>
                <!--end::Form-->
            </div>
            <!--end::Signin-->
        </div>
    </div>

    <div class="form-note"> Go Back to <a routerLink="/auth/login">Login</a></div>
</div>
