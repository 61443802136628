/*
// import the required animation functions from the angular animations module
import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('fadeInAnimation', [

        // route 'enter' transition
        transition(':enter', [

            // css styles at start of transition
            style({opacity: 0}),

            // animation and styles at end of transition
            animate('.3s', style({opacity: 1}))
        ]),
    ]);
*/


import { animate, query, style, transition, trigger } from '@angular/animations';

export const fadeAnimation =

    trigger('fadeAnimation', [

        transition('* => *', [

            query(':enter',
                [
                    style({opacity: 0})
                ],
                {optional: true}
            ),

            query(':leave',
                [
                    style({opacity: 1}),
                    animate('0.0s', style({opacity: 0}))
                ],
                {optional: true}
            ),

            query(':enter',
                [
                    style({opacity: 0}),
                    animate('0.0s', style({opacity: 1}))
                ],
                {optional: true}
            )

        ])

    ]);
