import {
    AfterViewInit,
    Component,
    HostListener,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    ViewEncapsulation
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'dg-menu-items',
    templateUrl: './menu.component.html',
    styleUrls: [ './menu.component.scss' ],
    animations: [
        trigger('toggleHeight', [
            state(
                'close',
                style({
                    height: '0',
                    overflow: 'hidden'
                })
            ),
            state(
                'open',
                style({
                    height: '*',
                    overflow: 'hidden'
                })
            ),
            transition('close => open', animate('140ms ease-in')),
            transition('open => close', animate('140ms ease-out'))
        ])
    ],
    encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, AfterViewInit {
    menuItems = [];

    currentItem = null;
    isPerfectScrollbarDisabled = false;

    public config: PerfectScrollbarConfigInterface = {};

    constructor(
        @Inject(PLATFORM_ID) private platformId: object
    ) {
    }

    @Input()
    set Items(value) {
        this.menuItems = value;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.togglePerfectScrollbar();
        });
    }

    @HostListener('window:resize', [])
    onResize(): void {
        this.togglePerfectScrollbar();
    }

    togglePerfectScrollbar(): void {
        if ( isPlatformBrowser(this.platformId) ) {
            this.isPerfectScrollbarDisabled = window.innerWidth < 1025;
        }
    }

    toggleNavigationSub(event, item): void {
        event.preventDefault();
        if ( this.currentItem && this.currentItem !== item ) {
            this.currentItem.toggle = 'close';
        }
        this.currentItem = item;
        item.toggle = item.toggle === 'close' ? 'open' : 'close';
    }
}
