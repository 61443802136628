// Angular
import { Injectable } from '@angular/core';
// RxJS
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Services
// Actions
import { AllPermissionsRequested } from '../_actions/permission.actions';

// Models

@Injectable()
export class PermissionEffects {
    /*@Effect()
    loadAllPermissions$ = this.actions$
      .pipe(
        ofType<AllPermissionsRequested>(PermissionActionTypes.AllPermissionsRequested),
        mergeMap(() => this.auth.getAllPermissions()),
        map((result: Permission[]) => {
          return new AllPermissionsLoaded({
            permissions: result
          });
        })
      );*/

    @Effect()
    init$: Observable<Action> = defer(() => {
        return of(new AllPermissionsRequested());
    });

    constructor(private actions$: Actions) {
    }
}
