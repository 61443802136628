import {
  Component,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  Inject,
  forwardRef,
  Input,
  ViewChild,
  TemplateRef,
  ContentChild,
  HostListener,
  HostBinding, OnDestroy, PLATFORM_ID
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToggleService } from '../../services/toggler.service';
import {isPlatformBrowser} from '@angular/common';

declare var pg: any;

@Component({
  selector: 'dg-sidebar',
  templateUrl: './sidebar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  pin = false;
  drawer = false;
  sidebar;
  timer;
  @HostBinding('style.transform')
  style: string;

  @HostBinding('class') class = 'page-sidebar';

  private sideBarWidth = 280;
  private sideBarWidthCondensed = 280 - 70;

  @ContentChild('sideBarOverlay', { static: true }) sideBarOverlay: TemplateRef<void>;
  @ContentChild('sideBarHeader', { static: true }) sideBarHeader: TemplateRef<void>;
  @ContentChild('menuItems', { static: true }) menuItems: TemplateRef<void>;

  @HostBinding('class.visible') mobileSidebar: boolean;

  @HostListener('mouseenter', ['$event'])
  @HostListener('click', ['$event'])
  openSideBar(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (pg.isVisibleSm() || pg.isVisibleXs()) {
        return false;
      }
      if (this.pin) {
        return false;
      }

      this.style = 'translate3d(' + this.sideBarWidthCondensed + 'px, 0,0)';
      pg.addClass(document.body, 'sidebar-visible');
    }
  }

  closeSideBar(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (pg.isVisibleSm() || pg.isVisibleXs()) {
        return false;
      }
      if (this.pin) {
        return false;
      }
      this.style = 'translate3d(0,0,0)';
      pg.removeClass(document.body, 'sidebar-visible');
      // this.drawer = false;
    }
  }

  toggleMenuPin(): void {
    this.pin = !this.pin;
  }

  toggleDrawer(): void {
    this.drawer = !this.drawer;
  }

  toggleMobile(toggle: boolean): void {
    clearTimeout(this.timer);
    if (toggle) {
      this.mobileSidebar = toggle;
    } else {
      this.timer = setTimeout(() => {
        this.mobileSidebar = toggle;
      }, 400);
    }
  }

  constructor(private appSidebar: ElementRef,
              private toggler: ToggleService,
              @Inject(PLATFORM_ID) private platformId: object) {
    this.subscriptions.push(
      this.toggler.sideBarToggle.subscribe(toggle => {
        this.toggleMobile(toggle);
      })
    );
    this.subscriptions.push(
      this.toggler.pageContainerHover.subscribe(message => {
        this.closeSideBar();
      })
    );
    this.subscriptions.push(
      this.toggler.menuDrawer.subscribe(message => {
        this.toggleDrawer();
      })
    );
    this.mobileSidebar = false;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
    clearTimeout(this.timer);
  }
}
