import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
    AllServicesLoaded,
    AllServicesLoadFailed,
    CancelServiceFailed,
    CancelServiceSuccess,
    DeleteServiceFailed,
    DeleteServiceSuccess,
    ServiceActionTypes,
    StartServiceFailed,
    StartServiceSuccess,
    StopServiceFailed,
    StopServiceSuccess,
} from '../_actions/service.actions';

import { Service } from '../_models/service.model';
import { Link, Meta } from '../_models/pagination.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../../../../reducers';
import { NotificationService } from '../../../layout/components/notification/notification.service';
import { ServiceService } from '../../../../services/service.service';


@Injectable()
export class ServiceEffects {

    loadServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActionTypes.AllServicesRequested, ServiceActionTypes.AllServicesRequestedForNewProjectID),
            mergeMap((action: any) => {
                const payloadRequest = action.payload as any;
                return this.serviceService.getAllServices(payloadRequest).pipe(
                    map((result: any) => {
                            return new AllServicesLoaded({
                                data: result.data as Service[],
                                meta: result.meta as Meta,
                                links: result.links as Link,
                            });
                        }
                    ),
                    catchError((err, caught) => of(new AllServicesLoadFailed({ err, caught })))
                );
            })
        ));

    deleteService$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActionTypes.DeleteService),
            mergeMap(({ payload }) => {
                const payloadRequest = payload as Service;
                console.log(payload);
                return this.serviceService.deleteService(payloadRequest.id).pipe(
                    map(() => {
                        this.notification.success(`Service ${ payloadRequest } has been delete.`, {
                            Position: 'bottom-right',
                            Style: 'bar',
                            Duration: 2000,
                        });
                        return new DeleteServiceSuccess({ service: payload as any });
                    }),
                    catchError((err: HttpErrorResponse, caught) => {
                        if ( err.status === 422 ) {
                            this.notification.process422Errors(err.error.errors);
                        } else {
                            this.notification.error(err.error.message, {
                                Position: 'bottom-right',
                                Style: 'bar',
                                Duration: 2000,
                            });
                        }
                        return of(new DeleteServiceFailed({ err, caught }));
                    })
                );
            }),
        ));

    startService$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActionTypes.StartService),
            mergeMap(({ payload }) => {
                const payloadRequest = payload as any;
                return this.serviceService.updateService(payloadRequest.projectId, payloadRequest.serviceId, 'start').pipe(
                    map((res: any) => {
                        this.notification.success(`Service ${ res.data.name } has been started.`, {
                            Position: 'bottom-right',
                            Style: 'bar',
                            Duration: 2000,
                        });
                        return new StartServiceSuccess({ service: res.data as any });
                    }),
                    catchError((err: HttpErrorResponse, caught) => {
                        if ( err.status === 422 ) {
                            this.notification.process422Errors(err.error.errors);
                        } else {
                            this.notification.error(err.error.message, {
                                Position: 'bottom-right',
                                Style: 'bar',
                                Duration: 2000,
                            });
                        }
                        return of(new StartServiceFailed({ err, caught }));
                    })
                );
            }),
        ));

    cancelService$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActionTypes.CancelService),
            mergeMap(({ payload }) => {
                const payloadRequest = payload as any;
                return this.serviceService.updateService(payloadRequest.projectId, payloadRequest.serviceId, 'cancel').pipe(
                    map((res: any) => {
                        this.notification.error(`Service ${ res.data.name } has been cancelled.`, {
                            Position: 'bottom-right',
                            Style: 'bar',
                            Duration: 2000,
                        });
                        return new CancelServiceSuccess({ service: res.data as any });
                    }),
                    catchError((err: HttpErrorResponse, caught) => {
                        if ( err.status === 422 ) {
                            this.notification.process422Errors(err.error.errors);
                        } else {
                            this.notification.error(err.error.message, {
                                Position: 'bottom-right',
                                Style: 'bar',
                                Duration: 2000,
                            });
                        }
                        return of(new CancelServiceFailed({ err, caught }));
                    })
                );
            }),
        ));

    StopService$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActionTypes.StopService),
            mergeMap(({ payload }) => {
                const payloadRequest = payload as any;
                return this.serviceService.updateService(payloadRequest.projectId, payloadRequest.serviceId, 'stop').pipe(
                    map((res: any) => {
                        this.notification.error(`Service ${ res.data.name } has been stopped.`, {
                            Position: 'bottom-right',
                            Style: 'bar',
                            Duration: 2000,
                        });
                        return new StopServiceSuccess({ service: res.data as any });
                    }),
                    catchError((err: HttpErrorResponse, caught) => {
                        if ( err.status === 422 ) {
                            this.notification.process422Errors(err.error.errors);
                        } else {
                            this.notification.error(err.error.message, {
                                Position: 'bottom-right',
                                Style: 'bar',
                                Duration: 2000,
                            });
                        }
                        return of(new StopServiceFailed({ err, caught }));
                    })
                );
            }),
        ));

    constructor(private actions$: Actions,
                private serviceService: ServiceService,
                private store: Store<AppState>,
                private notification: NotificationService,
    ) {
    }
}
