import {Directive, ElementRef, HostListener, Renderer2, HostBinding, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

declare var pg: any;

@Directive({
  selector: '[dgFormGroupDefault]'
})
export class FormGroupDefaultDirective implements OnInit {
  @HostBinding('class.focused') isActive$ = false;

  @HostListener('click') onclick(): void {
    if (this.isActive$) {
        return;
    }
    this.isActive$ = true;
    const inputEl = this.El.nativeElement.querySelector('input');
    if (inputEl) {
      inputEl.focus();
    }
  }

  constructor(private El: ElementRef,
              private renderer: Renderer2,
              @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    const inputEl = this.El.nativeElement.querySelector('input');
    const labelEl = this.El.nativeElement.querySelector('label');
    if (inputEl) {
      this.renderer.listen(inputEl, 'focus', event => {
        this.isActive$ = true;
      });
      this.renderer.listen(inputEl, 'focusout', event => {
        this.isActive$ = false;
        if (isPlatformBrowser(this.platformId)) {
          if (!inputEl.value) {
            pg.removeClass(labelEl, 'fade');
          } else {
            pg.addClass(labelEl, 'fade');
          }
        }
      });
    }
  }
}
