import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

declare var pg: any;

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[dg-view-trigger]'
})
export class ViewDirective {
    @Input() parentView: string;
    @Input() animationType: string;

    constructor(private parallaxEl: ElementRef, private renderer: Renderer2) {
    }

    @HostListener('click', [ '$event' ])
    onClick(e): void {
        e.preventDefault();
        if ( this.parentView != null ) {
            const parent = document.getElementById(this.parentView);
            if ( parent ) {
                if ( this.animationType != null ) {
                    pg.toggleClass(parent, this.animationType);
                } else {
                    pg.toggleClass(parent, 'push-parrallax');
                }
            }
        }
    }
}
