import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToggleService } from '../../services/toggler.service';
import { QuickviewService } from './quickview.service';
import { Note } from './note';
import { chatHistory, chatMessage } from './message';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { selectNewNotifications, selectNotificationMeta } from '../../../user/store/_selectors/notification.selectors';
import { Notification } from '../../../user/store/_models/notification.model';
import {
    AllNotificationMarkAsReadRequested,
    AllNotificationsRequested,
    NotificationMarkAsReadRequested,
    ShowDetailedNotification
} from '../../../user/store/_actions/notification.actions';
import { Meta } from '../../../user/store/_models/pagination.model';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'dg-quickview',
    templateUrl: './quickview.component.html',
    styleUrls: [ './quickview.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class QuickviewComponent implements OnInit, OnDestroy {
    subscriptions: Array<Subscription> = [];
    isOpen = false;
    noteList = [];
    noteDeleteList = [];
    // Single
    selectedNote: Note;
    noteText = '';
    // List for deleting or CRUD functions
    deleteNoteMode = false;
    isNoteOpen = false;
    userList = [];
    chatHistory: chatHistory;
    userMessage;
    newMessage: chatMessage;
    editorModules = {
        // https://github.com/KillerCodeMonkey/ngx-quill
        toolbar: [ [ { header: [ 1, 2, 3, 4, false ] } ], [ 'bold', 'italic', 'underline' ], [ 'link', 'image' ] ]
    };
    @ViewChild('chatHistoryWrapper', { static: true }) chatHistoryWrapper: ElementRef;

    public notifications$: Observable<Notification[]>;
    public meta = new Meta(10, 1);
    public meta$: Observable<Meta>;
    private unsubscribe: Subject<any>;

    constructor(private service$: QuickviewService,
                private http: HttpClient,
                private toggler: ToggleService,
                private store: Store<AppState>) {
        this.unsubscribe = new Subject<any>();

        this.subscriptions.push(
            this.toggler.quickViewToggle.subscribe(message => {
                this.toggle();
            })
        );

        this.store.dispatch(new AllNotificationsRequested({
            perPage: this.meta.per_page,
            currentPage: this.meta.current_page
        }));

        this.meta$ = this.store.pipe(select(selectNotificationMeta));

        this.notifications$ = this.store.select(selectNewNotifications);
        this.meta$.pipe(takeUntil(this.unsubscribe)).subscribe((meta: Meta) => {
            this.meta = meta;
        });
    }

    public loadMoreNotifications(): void {
        this.store.dispatch(new AllNotificationsRequested({
            perPage: this.meta.per_page,
            currentPage: this.meta.current_page + 1
        }));
    }

    public markAllASRead(notifications: Notification[]): void {
        this.store.dispatch(new AllNotificationMarkAsReadRequested({
            notifications
        }));
    }

    public markASRead(notification: Notification): void {
        this.store.dispatch(new NotificationMarkAsReadRequested({
            notification
        }));
    }

    public showDetailedNotification(notification: Notification): void {
        this.store.dispatch(new ShowDetailedNotification({
            notification
        }));
    }

    ngOnDestroy(): void {
        //  unsubscribe to ensure no memory leaks
        for ( const sub of this.subscriptions ) {
            sub.unsubscribe();
        }
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit(): void {
        //  Retrieve posts from the API
        /*this.subscriptions.push(
          this.service$.getNotes().subscribe(notes => {
            this.noteList = notes;
          })
        );

        this.subscriptions.push(
          this.service$.getUsers().subscribe(users => {
            this.userList = users;
          })
        );

        this.subscriptions.push(
          this.service$.getChatMessages().subscribe(messages => {
            this.chatHistory = messages;
          })
        );*/
    }

    toggle(): void {
        this.isOpen = !this.isOpen;
    }

    popNote(item: Note): void {
        const index = this.noteDeleteList.indexOf(item);
        if ( index !== -1 ) {
            this.noteDeleteList.splice(index, 1);
        }
    }

    pushNote(item: Note): void {
        this.noteDeleteList.push(item);
    }

    onSelectNote(item: Note): void {
        if ( !this.deleteNoteMode ) {
            this.selectedNote = item;
            this.noteText = this.selectedNote.notes;
            this.isNoteOpen = true;
        }
    }

    toggleNotesView(): void {
        if ( this.isNoteOpen ) {
            this.isNoteOpen = false;
            this.saveNote();
        } else {
            this.isNoteOpen = true;
        }
    }

    onCheck(e, item: Note): void {
        if ( e.target.checked ) {
            this.pushNote(item);
        } else {
            this.popNote(item);
        }
    }

    composeNote(): void {
        this.isNoteOpen = true;
        this.selectedNote = new Note();
        this.selectedNote.id = this.noteList.length + 1;
        this.selectedNote.date = new Date();
        this.selectedNote.notes = '';
        this.noteText = '';
        this.noteList.push(this.selectedNote);
    }

    saveNote(): void {
        this.selectedNote.notes = this.noteText;
    }

    deleteMode(): void {
        this.deleteNoteMode = !this.deleteNoteMode;
    }

    deleteNote(): void {
        this.noteList = this.noteList.filter(item => this.noteDeleteList.indexOf(item) === -1);
    }

    onMessageKeyPress(event): void {
        if ( event.keyCode === 13 ) {
            if ( this.userMessage ) {
                this.newMessage = new chatMessage();
                this.newMessage.from = 'me';
                this.newMessage.date = '';
                this.newMessage.message = this.userMessage;
                this.chatHistory.log.push(this.newMessage);
                this.userMessage = '';
                this.chatHistoryWrapper.nativeElement.scrollTop = this.chatHistoryWrapper.nativeElement.scrollHeight;
            }
        }
    }
}
