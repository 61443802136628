import { Component, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { ToggleService } from '../../services/toggler.service';

@Component({
  selector: 'dg-page-container',
  template: '<ng-content></ng-content>',
  styleUrls: ['./page-container.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageContainerComponent {
  @HostBinding('class') class = 'page-container';

  constructor(private toggler: ToggleService) {}

  @HostListener('mouseenter', ['$event'])
  @HostListener('tap', ['$event'])

  triggerMouseOverCall(): void {
    this.toggler.triggerPageContainerHover(true);
  }
}
