import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { Subject } from 'rxjs';
import { AuthNoticeService } from '../../notice/auth-notice.service';
import { Router } from '@angular/router';

const EMAIL_REGEX = /^[a-zA-Z]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,}$/;

@Component({
    selector: 'dg-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: [ './forgot-password.component.scss' ]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    public forgotPasswordForm: FormGroup;
    public loading = false;
    private unsubscribe: Subject<any>;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private authNoticeService: AuthNoticeService,
        private router: Router
    ) {
        this.unsubscribe = new Subject<any>();
    }

    createForm(): FormGroup {
        return this.formBuilder.group({
            email: [ '', Validators.compose([ Validators.required, Validators.pattern(EMAIL_REGEX) ]) ]
        });
    }

    submit(): void {
        const controls = this.forgotPasswordForm.controls;
        /** check form */
        if ( this.forgotPasswordForm.invalid ) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.loading = true;

        const email = controls.email.value;
        this.authService.requestPassword(email).pipe(
            tap(response => {
                if ( response ) {
                    this.authNoticeService.setNotice('If you have an account with us, You should receive a email with instructions to reset your password shortly, Thanks', 'success', 'forgot-password');
                    this.router.navigateByUrl('/auth/login');
                } else {
                    this.authNoticeService.setNotice(`The requested ${ email } is not found`, 'danger', 'forgot-password');
                }
            }, () => {
                this.authNoticeService.setNotice(`The requested ${ email } is not found`, 'info', 'forgot-password');
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.loading = false;
                this.forgotPasswordForm.reset();
                this.cdr.markForCheck();
            })
        ).subscribe();
    }

    ngOnInit(): void {
        this.forgotPasswordForm = this.createForm();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
