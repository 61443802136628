import { NgModule } from '@angular/core';
import { ToggleService } from './services/toggler.service';

import { QuickviewService } from './primary-layout/quickview/quickview.service';

import { PrimaryLayoutModule } from './primary-layout/primary-layout.module';
import { BlankLayoutModule } from './blank-layout/blank-layout.module';

@NgModule({
  declarations: [],
  imports: [ PrimaryLayoutModule, BlankLayoutModule ],
  providers: [ ToggleService, QuickviewService ],
  exports: [ PrimaryLayoutModule, BlankLayoutModule ]
})
export class LayoutModule { }
