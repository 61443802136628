import { createFeatureSelector } from '@ngrx/store';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Notification } from '../_models/notification.model';
import { NotificationActions, NotificationActionTypes } from '../_actions/notification.actions';
import { Link, Meta } from '../_models/pagination.model';

export interface NotificationsState extends EntityState<Notification> {
    meta: Meta;
    links: Link;
    loading: boolean;
}

export function sortLatest(a: Notification, b: Notification): any {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateA > dateB ? -1 : 1;
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>(
    {
        sortComparer: sortLatest,
    }
);

export const initialNotificationsState: NotificationsState = adapter.getInitialState({
    meta: new Meta(),
    links: new Link(),
    loading: false
});

// tslint:disable-next-line:max-line-length
export function notificationsReducer(state: NotificationsState = initialNotificationsState, action: NotificationActions): NotificationsState {
    switch ( action.type ) {
        case NotificationActionTypes.AllNotificationsRequested:
            return {
                ...state,
                loading: true
            };

        case NotificationActionTypes.AllNotificationsLoadFailed:
            return {
                ...state,
                loading: false
            };

        case NotificationActionTypes.AllNotificationsLoaded:
            return adapter.addMany(action.payload.data, {
                ...state,
                meta: action.payload.meta,
                links: action.payload.links,
                loading: false
            });

        case NotificationActionTypes.NotificationSocketReceived:
            console.log(action.payload.notification);
            return adapter.upsertOne(action.payload.notification, {
                ...state,
                loading: false
            });

        case NotificationActionTypes.AllNotificationMarkAsReadSuccess:
            return adapter.updateMany(
                action.payload.notifications
                    .map((notification: Notification) =>
                        Object.assign({}, { id: notification.id, changes: { readAt: new Date().toISOString() } })),
                { ...state, loading: false }
            );

        case NotificationActionTypes.NotificationMarkAsReadSuccess:
            return adapter.updateOne(
                { id: action.payload.notification.id, changes: { readAt: new Date().toISOString() } },
                { ...state, loading: false }
            );

        case NotificationActionTypes.ShowDetailedNotification:
            return adapter.updateOne(
                { id: action.payload.notification.id, changes: { showDetails: !action.payload.notification.showDetails } },
                { ...state, loading: false }
            );

        default:
            return state;
    }
}

export const getNotificationState = createFeatureSelector<NotificationsState>('notification');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
