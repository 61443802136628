import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../interfaces/api-response.interface';


const GET_NOTIFICATIONS_URL = `${ environment.apiEndpoint }notification`;


@Injectable()
export class UserNotificationService {

    constructor(
        private http: HttpClient
    ) {
    }

    public getAllNotifications(payload: any): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(`${ GET_NOTIFICATIONS_URL }/list?per_page=${ payload.perPage }&page=${ payload.currentPage }`);
    }

    public markAllASRead(): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(`${ GET_NOTIFICATIONS_URL }/mark-all`, null);
    }

    public markASRead(notificationId: string): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(`${ GET_NOTIFICATIONS_URL }/mark-read/${ notificationId }`, null);
    }
}
