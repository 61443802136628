import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceModel } from '../modules/user/store/_models/service.model';
import { ApiResponse } from '../interfaces/api-response.interface';

const GET_SERVICES_URL = `${ environment.apiEndpoint }project/`;
const DELETE_SERVICES_URL = `${ environment.apiEndpoint }project/`;
const UPDATE_SERVICES_URL = `${ environment.apiEndpoint }project/`;

@Injectable()
export class ServiceService {

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
    }

    // CREATE =>  POST: add a new service to the server
    createService(formValue: any, coreServiceId: string, projectId: string): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(`${ GET_SERVICES_URL }${ projectId }/service/${ coreServiceId }/create`, formValue);
    }

    // CREATE =>  POST: add a new service to the server
    getService(serviceId: string, projectId: string): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(`${ GET_SERVICES_URL }${projectId}/service/${serviceId}/get`);
    }

    // DELETE => delete the service from the server
    deleteService(serviceId: string | number): Observable<ServiceModel> {
        const url = `${ DELETE_SERVICES_URL }${ serviceId }/remove`;
        return this.http.delete<ServiceModel>(url);
    }

    updateService(projectId: string, serviceId: string | number, eventType: string): Observable<ServiceModel> {
        const url = `${ UPDATE_SERVICES_URL }${ projectId }/service/${ serviceId }/${ eventType }`;
        return this.http.post<ServiceModel>(url, null);
    }

    // READ => read all services from the server
    getAllServices(service: any): Observable<ServiceModel> {
        let page = service.currentPage ? service.currentPage : 1;
       
        return this.http.get<ServiceModel>(`${ GET_SERVICES_URL }${ service.projectId }/service/all?per_page=${ service.perPage }&page=${ page }&service=${ service.coreServiceID }`);
    }
}
