import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayoutComponent } from '../root-layout/root-layout.component';
import { takeUntil } from 'rxjs/operators';
import { CoreService } from '../../user/store/_models/core-service.model';

@Component({
    selector: 'dg-primary-layout',
    templateUrl: './primary-layout.component.html',
    styleUrls: ['./primary-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PrimaryLayoutComponent extends RootLayoutComponent implements OnInit, OnDestroy {
    public servicesLabel = 'Services';

    public menuLinks = [
        {
            label: 'Dashboard',
            routerLink: '/user/dashboard',
            iconType: 'material-icons',
            iconName: 'home'
        },
        {
            label: this.servicesLabel,
            iconType: 'letter',
            iconName: 'P',
            toggle: 'open',
            submenu: []
        },
        {
            label: 'Account Settings',
            iconType: 'material-icons',
            iconName: 'settings',
            toggle: 'close',
            submenu: [
                {
                    label: 'Credit Transactions',
                    routerLink: '/user/profile/plan-history',
                    iconType: 'material-icons',
                    iconName: 'history'
                },
                {
                    label: 'Manage Cards',
                    routerLink: '/user/profile/manage-cards',
                    iconType: 'material-icons',
                    iconName: 'credit_card'
                },
                {
                    label: 'Personal Information',
                    routerLink: `/user/profile/info`,
                    iconType: 'material-icons',
                    iconName: 'info'
                },
                {
                    label: 'Change Password',
                    routerLink: `/user/profile/change-password`,
                    iconType: 'material-icons',
                    iconName: 'vpn_key'
                }
            ]
        },
    ];

    ngOnInit(): void {

        this.coreServices$.pipe(takeUntil(this.unsubscribe)).subscribe((services: CoreService[]) => {
            const serviceMenu = {
                label: this.servicesLabel,
                iconType: 'material-icons',
                iconName: 'data_usage',
                toggle: 'open',
                submenu: []
            };

            services.forEach((coreService: CoreService) => {
              if (! coreService.disable_service) {
                serviceMenu.submenu.push({
                    label: coreService.friendlyName,
                    routerLink: `/projects/${coreService.defaultProject}/services/${coreService.id}`,
                    iconType: 'letter',
                    iconName: coreService.name.charAt(0)
                });
              }

            });

            const index = this.menuLinks.findIndex(menu => menu.label === this.servicesLabel);

            if (index < 0) {
                this.menuLinks.push(serviceMenu);
            } else {
                this.menuLinks[index] = serviceMenu;
            }

            this.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
