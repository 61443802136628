import {Directive, Input, OnInit, ElementRef, Renderer2, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Directive({
  selector: '[dgRetina]'
})
export class RetinaDirective implements OnInit {
  isRetina = false;
  srcRetina$;
  src$;

  constructor(private El: ElementRef,
              private renderer: Renderer2,
              @Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformBrowser(this.platformId)) {
      this.isRetina = window.devicePixelRatio > 1;
    }
  }

  @Input()
  set src2x(value: string) {
    this.srcRetina$ = value;
  }

  @Input()
  set src1x(value: string) {
    this.src$ = value;
  }

  ngOnInit(): void {
    if (this.isRetina) {
      this.renderer.setAttribute(this.El.nativeElement, 'src', this.srcRetina$);
    }
  }
}
