import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Link, Meta } from '../_models/pagination.model';
import { CoreService } from '../_models/core-service.model';
import {
    AllCoreServiceLoaded,
    AllCoreServiceLoadFailed,
    CoreServiceActionTypes
} from '../_actions/core-service.actions';
import { CoreServiceService } from '../../../../services/core-service.service';

@Injectable()
export class CoreServiceEffects {

    loadCoreService$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CoreServiceActionTypes.AllCoreServiceRequested),
            mergeMap(() => this.coreServiceService.getAllCoreServices().pipe(
                map((result: any) => {
                        return new AllCoreServiceLoaded({
                            data: result.data as CoreService[],
                            meta: result.meta as Meta,
                            links: result.links as Link,
                        });
                    }
                ),
                catchError((err, caught) => of(new AllCoreServiceLoadFailed({ err, caught })))
            ))
        ));

    constructor(private actions$: Actions,
                private coreServiceService: CoreServiceService) {
    }
}
