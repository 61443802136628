import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
    AllProjectsLoaded,
    AllProjectsLoadFailed,
    NewProjectCreationFailed,
    NewProjectCreationSuccess,
    ProjectActionTypes,
    ProjectDeleteFailed,
    ProjectDeleteSuccess
} from '../_actions/project.actions';

import { Project } from '../_models/project.model';
import { Link, Meta } from '../_models/pagination.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectService } from '../../../../services/project.service';
import { AppState } from '../../../../reducers';
import { NotificationService } from '../../../layout/components/notification/notification.service';

@Injectable()
export class ProjectEffects {

    loadProjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActionTypes.AllProjectsRequested),
            mergeMap(() => this.projectService.getAllProjects().pipe(
                map((result: any) => {
                        return new AllProjectsLoaded({
                            data: result.data as Project[],
                            meta: result.meta as Meta,
                            links: result.links as Link,
                        });
                    }
                ),
                catchError((err, caught) => of(new AllProjectsLoadFailed({ err, caught })))
            ))
        ));

    createProject$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActionTypes.CreateNewProject),
            exhaustMap(({ payload }) => {
                const payloadRequest = payload as any;
                return this.projectService.createProject(payloadRequest.name).pipe(
                    map(res => {
                        this.notification.success('New project has been created.', {
                            Position: 'bottom-right',
                            Style: 'bar',
                            Duration: 2000,
                        });
                        return new NewProjectCreationSuccess({ project: res.data });
                    }),
                    catchError((err, caught) => {
                        this.notification.process422Errors(err.error.errors);
                        return of(new NewProjectCreationFailed({ err, caught }));
                    })
                );
            }),
        ));

    deleteProject$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActionTypes.DeleteProject),
            mergeMap(({ payload }) => {
                const payloadRequest = payload as Project;
                console.log(payload);
                return this.projectService.deleteProject(payloadRequest.id).pipe(
                    map(res => {
                        this.notification.success(`Project ${ payloadRequest.name } has been deleted.`, {
                            Position: 'bottom-right',
                            Style: 'bar',
                            Duration: 2000,
                        });
                        return new ProjectDeleteSuccess({ project: payload as any });
                    }),
                    catchError((err: HttpErrorResponse, caught) => {
                        if ( err.status === 422 ) {
                            this.notification.process422Errors(err.error.errors);
                        } else {
                            this.notification.error(err.error.message, {
                                Position: 'bottom-right',
                                Style: 'bar',
                                Duration: 2000,
                            });
                        }
                        return of(new ProjectDeleteFailed({ err, caught }));
                    })
                );
            }),
        ));

    constructor(private actions$: Actions,
                private projectService: ProjectService,
                private store: Store<AppState>,
                private notification: NotificationService) {
    }
}
