// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    authTokenKey: 'auth-token-8847-3823-88493',
    authEndpoint: 'https://services.datagro.io/api/v1/',
    apiEndpoint: 'https://services.datagro.io/api/v1/',
    pusher: {
        PUSHER_APP_ID: '227526',
        PUSHER_APP_KEY: '830f625937579d6bfc91',
        PUSHER_APP_SECRET: '24a493ab0743654b2ec2',
        PUSHER_APP_CLUSTER: 'mt1',
    },
    stripeKey: {
        public: 'pk_test_51Mi9wnFJ79zbyf7WjZUGJxV49R4jYuYHrumTi4AYewf6qaoaxMKEvCMjMpWbQQSlOlHdoXjuwm6M1D1zkYEupHQL00N86KJcLM'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
