<ng-container *ngIf="container === noticeBoardId">
    <ng-template #alertNotice>
        <div [hidden]="!notice" class="alert alert-{{type}}" role="alert">
            <div class="alert-text" [innerHTML]="notice"></div>
        </div>
    </ng-template>

    <div [hidden]="!notice" class="alert alert-{{type}}" role="alert" *ngIf="typeof(notice) === 'array'; else alertNotice">
        <ul>
            <li *ngFor="let msg of notice" [innerHTML]="msg"></li>
        </ul>
    </div>
</ng-container>
