import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';



@NgModule({
  declarations: [AuthNoticeComponent],
  exports: [
    AuthNoticeComponent
  ],
  imports: [
    CommonModule
  ]
})
export class NoticeModule { }
