import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NotificationContainerComponent } from './notification-container.component';
import { MessageDataFilled, MessageDataOptions } from './notification.definitions';
import { AppStatusService } from '../../../../services/app-status.service';
import { isPlatformBrowser } from '@angular/common';

declare var pg: any;

@Component({
    selector: 'dg-message',
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('enterLeave', [
            state('enter', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('* => enter', [ style({ opacity: 0, transform: 'translateY(-50%)' }), animate('100ms linear') ]),
            state('leave', style({ opacity: 0, transform: 'translateY(-50%)' })),
            transition('* => leave', [ style({ opacity: 1, transform: 'translateY(0)' }), animate('100ms linear') ])
        ])
    ],
    templateUrl: 'notification.component.html',
    styleUrls: []
})
export class NotificationComponent implements OnInit, OnDestroy {
    @Input() Message: MessageDataFilled;
    @Input() Index: number;
    enableHorizontalContainer$;
    options$: MessageDataOptions; // Shortcut reference to Message.options

    // For auto erasing(destroy) self
    private autoErase$: boolean; // Whether record timeout to auto destroy self
    private eraseTimer$: number = null;
    private eraseTimingStart$: number;
    private eraseTTL$: number; // Time to live

    constructor(private messageContainer$: NotificationContainerComponent,
                private appStatus: AppStatusService,
                @Inject(PLATFORM_ID) private platformId: object) {
    }

    ngOnInit(): void {
        this.options$ = this.Message.options;
        if ( this.options$.Animate ) {
            this.Message.state = 'enter';
        }

        this.autoErase$ = this.options$.Duration > 0;

        if ( this.autoErase$ ) {
            this._initErase();
            this._startEraseTimeout();
        }

        this.enableHorizontalContainer$ = pg.isHorizontalLayout;
    }

    ngOnDestroy(): void {
        if ( this.autoErase$ ) {
            this._clearEraseTimeout();
        }
    }

    onEnter(): void {
        if ( this.autoErase$ && this.options$.PauseOnHover ) {
            this._clearEraseTimeout();
            this._updateTTL();
        }
    }

    onLeave(): void {
        if ( this.autoErase$ && this.options$.PauseOnHover ) {
            this._startEraseTimeout();
        }
    }

    onClickClose(): void {
        this._destroy();
    }

    onClickReload(): void {
        this.appStatus.reloadApp();
    }

    // Remove self
    protected _destroy(): void {
        if ( this.options$.Animate ) {
            this.Message.state = 'leave';
            setTimeout(() => this.messageContainer$.removeMessage(this.Message.messageId), 200);
        } else {
            this.messageContainer$.removeMessage(this.Message.messageId);
        }
    }

    private _initErase(): void {
        this.eraseTTL$ = this.options$.Duration;
        this.eraseTimingStart$ = Date.now();
    }

    private _updateTTL(): void {
        if ( this.autoErase$ ) {
            this.eraseTTL$ -= Date.now() - this.eraseTimingStart$;
        }
    }

    private _startEraseTimeout(): void {
        if ( isPlatformBrowser(this.platformId) ) {
            if ( this.eraseTTL$ > 0 ) {
                this._clearEraseTimeout(); // To prevent calling _startEraseTimeout() more times to create more timer
                this.eraseTimer$ = window.setTimeout(() => this._destroy(), this.eraseTTL$);
                this.eraseTimingStart$ = Date.now();
            } else {
                this._destroy();
            }
        }
    }

    private _clearEraseTimeout(): void {
        if ( isPlatformBrowser(this.platformId) ) {
            if ( this.eraseTimer$ !== null ) {
                window.clearTimeout(this.eraseTimer$);
                this.eraseTimer$ = null;
            }
        }
    }
}
