import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
    declarations: [ LogoComponent ],
    exports: [
        LogoComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class LogoModule {
}
