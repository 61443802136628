import { Action } from '@ngrx/store';
import { Project } from '../_models/project.model';
import { Link, Meta } from '../_models/pagination.model';

export enum ProjectActionTypes {
    AllProjectsRequested = '[Projects API] All Projects Requested',
    AllProjectsLoaded = '[Projects API] All Projects Loaded',
    AllProjectsLoadFailed = '[Projects API] All Projects Load Failed',
    CreateNewProject = '[Projects API] New Project Creation Request',
    NewProjectCreationSuccess = '[Projects API] New Project Creation Success',
    NewProjectCreationFailed = '[Projects API] New Project Creation Failed',

    DeleteProject = '[Projects API] Project Delete Request',
    ProjectDeleteSuccess = '[Projects API] Project Delete Success',
    ProjectDeleteFailed = '[Projects API] Project Delete Failed'
}

export class AllProjectsRequested implements Action {
    readonly type = ProjectActionTypes.AllProjectsRequested;
}

export class AllProjectsLoaded implements Action {
    readonly type = ProjectActionTypes.AllProjectsLoaded;

    constructor(public payload: { data: Project[], meta: Meta, links: Link }) {
    }
}

export class AllProjectsLoadFailed implements Action {
    readonly type = ProjectActionTypes.AllProjectsLoadFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class CreateNewProject implements Action {
    readonly type = ProjectActionTypes.CreateNewProject;

    constructor(public payload: { name: string }) {
    }
}

export class NewProjectCreationSuccess implements Action {
    readonly type = ProjectActionTypes.NewProjectCreationSuccess;

    constructor(public payload: { project: Project }) {
    }
}

export class NewProjectCreationFailed implements Action {
    readonly type = ProjectActionTypes.NewProjectCreationFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export class DeleteProject implements Action {
    readonly type = ProjectActionTypes.DeleteProject;

    constructor(public payload: { project: Project }) {
    }
}

export class ProjectDeleteSuccess implements Action {
    readonly type = ProjectActionTypes.ProjectDeleteSuccess;

    constructor(public payload: { project: Project }) {
    }
}

export class ProjectDeleteFailed implements Action {
    readonly type = ProjectActionTypes.ProjectDeleteFailed;

    constructor(public payload: { err: any, caught: any }) {
    }
}

export type ProjectActions = AllProjectsRequested
    | AllProjectsLoaded
    | AllProjectsLoadFailed
    | CreateNewProject
    | NewProjectCreationSuccess
    | NewProjectCreationFailed
    | DeleteProject
    | ProjectDeleteSuccess
    | ProjectDeleteFailed;
