import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RetinaDirective } from './directives/retina/retina.directive';
import { FormGroupDefaultDirective } from './directives/forms/form-group-default.directive';
import { ViewDirective } from '../layout/components/view/view.directive';

const modules: any[] = [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
];

@NgModule({
    declarations: [ RetinaDirective, FormGroupDefaultDirective, ViewDirective ],
    imports: modules,
    exports: [
        modules,
        RetinaDirective,
        FormGroupDefaultDirective,
    ]
})
export class SharedModule {
}
