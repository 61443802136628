import {
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ToggleService } from '../services/toggler.service';
import { isPlatformBrowser } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { currentUser, Logout, User } from '../../../@auth';
import { Projects } from '@angular/cli/lib/config/schema';
import { selectAllProjects } from '../../user/store';
import { CoreService } from '../../user/store/_models/core-service.model';
import { selectAllCoreService } from '../../user/store/_selectors/core-service.selectors';
import { fadeAnimation } from '../components/animations/fade-animations';

declare var pg: any;

@Component({
    selector: 'dg-root-layout',
    templateUrl: './root-layout.component.html',
    animations: [fadeAnimation]
})
export class RootLayoutComponent implements OnInit, OnDestroy {

    @ViewChild('root', {static: false}) root;

    public unsubscribe: Subject<any>;

    layoutState: string;
    extraLayoutClass: string;
    boxed$ = false;
    menuPin$ = true;
    enableHorizontalContainer$: boolean;
    pageContainerClass$ = '';
    contentClass$ = '';
    footer$ = true;
    menuDrawerOpen$ = false;
    // Mobile
    secondarySideBar$ = false;
    // Mobile
    mobileSidebar$ = false;
    // Mobile
    mobileHorizontalMenu$ = false;
    pageTitle$: string;
    // Sub layout - eg: email
    layoutOption$: string;
    subscriptions$: Array<Subscription> = [];
    layout$;
    projects$: Observable<Projects[]>;
    coreServices$: Observable<CoreService[]>;
    user$: Observable<User>;

    @Input()
    public contentClass = '';

    @Input()
    public pageWrapperClass = '';

    @Input()
    public footer = true;

    constructor(public toggler: ToggleService,
                private router: Router,
                @Inject(PLATFORM_ID) private platformId: object,
                private store: Store<AppState>,
                protected cdr: ChangeDetectorRef
    ) {
        this.unsubscribe = new Subject<any>();

        this.projects$ = this.store.pipe(select(selectAllProjects));
        this.coreServices$ = this.store.pipe(select(selectAllCoreService));
        this.user$ = this.store.pipe(select(currentUser));

        if (this.layoutState) {
            if (isPlatformBrowser(this.platformId)) {
                pg.addClass(document.body, this.layoutState);
            }
        }

        if (this.menuPin$) {
            if (isPlatformBrowser(this.platformId)) {
                pg.addClass(document.body, 'menu-pin');
            }
        }
        router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                let root = this.router.routerState.snapshot.root;
                while (root) {
                    if (root.children && root.children.length) {
                        root = root.children[0];
                    } else if (root.data) {
                        // Custom Route Data here
                        this.pageTitle$ = root.data.title;
                        this.layoutOption$ = root.data.layoutOption;
                        this.boxed$ = root.data.boxed;
                        break;
                    } else {
                        break;
                    }
                }
                // Reset Any Extra Layouts added from content pages
                if (isPlatformBrowser(this.platformId)) {
                    pg.removeClass(document.body, this.extraLayoutClass);
                }
                // Close Sidebar and Horizonta Menu
                if (this.mobileSidebar$) {
                    this.mobileSidebar$ = false;
                    if (isPlatformBrowser(this.platformId)) {
                        pg.removeClass(document.body, 'sidebar-open');
                    }
                    this.toggler.toggleMobileSideBar(this.mobileSidebar$);
                }
                this.mobileHorizontalMenu$ = false;
                this.toggler.toggleMobileHorizontalMenu(this.mobileHorizontalMenu$);
                // Scoll Top
                this.scrollToTop();
            }

            // Subscribition List
            this.subscriptions$.push(
                this.toggler.pageContainerClass.subscribe(state => {
                    this.pageContainerClass$ = state;
                })
            );

            this.subscriptions$.push(
                this.toggler.contentClass.subscribe(state => {
                    this.contentClass$ = state;
                })
            );

            this.subscriptions$.push(
                this.toggler.bodyLayoutClass.subscribe(state => {
                    if (state) {
                        this.extraLayoutClass = state;
                        if (isPlatformBrowser(this.platformId)) {
                            pg.addClass(document.body, this.extraLayoutClass);
                        }
                    }
                })
            );

            this.subscriptions$.push(
                this.toggler.Applayout.subscribe(state => {
                    this.changeLayout(state);
                })
            );

            this.subscriptions$.push(
                this.toggler.Footer.subscribe(state => {
                    this.footer$ = state;
                })
            );

            this.subscriptions$.push(
                this.toggler.mobileHorizontaMenu.subscribe(state => {
                    this.mobileHorizontalMenu$ = state;
                })
            );
        });
    }

    public logout(): void {
        this.store.dispatch(new Logout());
    }

    public changeLayout(type: string): void {
        this.layoutState = type;
        if (type) {
            if (isPlatformBrowser(this.platformId)) {
                pg.addClass(document.body, type);
            }
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        for (const sub of this.subscriptions$) {
            sub.unsubscribe();
        }
    }

    public scrollToTop(): void {
        if (isPlatformBrowser(this.platformId)) {
            const top = window.pageYOffset;
            if (top === 0) {
                const scroller = document.querySelector('.page-container');
                if (scroller) {
                    scroller.scrollTo(0, 0);
                }
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    public openQuickView($e): void {
        $e.preventDefault();
        this.toggler.toggleQuickView();
    }

    openSearch($e): void {
        $e.preventDefault();
        this.toggler.toggleSearch(true);
    }

    public toggleMenuPin($e): void {
        if (isPlatformBrowser(this.platformId)) {
            if (pg.isVisibleSm()) {
                this.menuPin$ = false;
                return;
            }
            if (this.menuPin$) {
                pg.removeClass(document.body, 'menu-pin');
                this.menuPin$ = false;
            } else {
                pg.addClass(document.body, 'menu-pin');
                this.menuPin$ = true;
            }
        }
    }

    toggleMenuDrawer(): void {
        this.menuDrawerOpen$ = this.menuDrawerOpen$ !== true;
        this.toggler.toggleMenuDrawer();
    }

    toggleMobileSidebar(): void {
        if (isPlatformBrowser(this.platformId)) {
            if (this.mobileSidebar$) {
                this.mobileSidebar$ = false;
                pg.removeClass(document.body, 'sidebar-open');
            } else {
                this.mobileSidebar$ = true;
                pg.addClass(document.body, 'sidebar-open');
            }
            this.toggler.toggleMobileSideBar(this.mobileSidebar$);
        }
    }

    toggleSecondarySideBar(): void {
        console.log('hi');
        this.secondarySideBar$ = this.secondarySideBar$ !== true;
        this.toggler.toggleSecondarySideBar(this.secondarySideBar$);
    }

    toggleHorizontalMenuMobile(): void {
        this.mobileHorizontalMenu$ = this.mobileHorizontalMenu$ !== true;
        this.toggler.toggleMobileHorizontalMenu(this.mobileHorizontalMenu$);
    }

    @HostListener('window:resize', [])
    onResize(): void {
        this.autoHideMenuPin();
    }

    // Utils
    autoHideMenuPin(): void {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth < 1025) {
                if (pg.hasClass(document.body, 'menu-pin')) {
                    pg.addClass(document.body, 'menu-unpinned');
                    pg.removeClass(document.body, 'menu-pin');
                }
            } else {
                if (pg.hasClass(document.body, 'menu-unpinned')) {
                    pg.addClass(document.body, 'menu-pin');
                }
            }
        }
    }

    private removeLayout(type: string): void {
        if (isPlatformBrowser(this.platformId)) {
            pg.removeClass(document.body, type);
        }
    }

}
