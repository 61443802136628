<div id="quickview" class="quickview-wrapper" [class.open]=isOpen>
	<!-- Nav tabs -->
	<tabset>
		<tab heading="Notifications" id="quickview-alerts" customClass="full-height" *ngIf="(notifications$ | async) as notifications">
			<div class="view-port clearfix" id="alerts">
				<!-- BEGIN Alerts View !-->
				<div class="view bg-white">
					<div class="full-height d-flex flex-column">
						<!-- BEGIN View Header !-->
						<div class="navbar navbar-default navbar-sm">
							<div class="navbar-inner">
								<!--<div class="view-heading">
									Notifications
								</div>-->

								<a href="javascript:void(0)"
								   class="action p-r-10 pull-right link text-color fs-12"
								   (click)="markAllASRead(notifications)"
								>
									Mark All Read
								</a>
							</div>
						</div>
						<!-- END View Header !-->
						<!-- BEGIN Alert List !-->
						<div perfectScrollbar class="full-height list-view boreded no-top-border">
							<div class="list-view-group-container">
								<!-- BEGIN List Group Header!-->
								<!--<div class="list-view-group-header text-uppercase">
									Older
								</div>-->
								<!-- END List Group Header!-->
								<ul>
									<!-- BEGIN List Group Item!-->
									<li class="alert-list" *ngFor="let notification of notifications">
										<!-- BEGIN Alert Item Set Animation using data-view-animation !-->
										<p class="" (click)="markASRead(notification)">
											<span class="fs-10" [ngClass]="[notification.readAt ? 'text-light' : 'text-success']"><i class="pg-icon">circle_fill</i></span>
										</p>
										<a href="javascript:;"
										   class="p-t-10 p-b-10 align-items-center"
										   data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax"
										   (click)="showDetailedNotification(notification)"
										>
											<p class="col fs-12 p-l-10" [ngClass]="[notification.showDetails ? '' : 'overflow-ellipsis']">
												<span class="text-color link">{{ notification.createdAt | date: 'dd MMM yyyy hh:mm:ss' }}<br></span>
												<span class="text-color">{{ notification.data.message }}</span>
											</p>
										</a>
										<!-- END Alert Item!-->
									</li>
									<!-- END List Group Item!-->
								</ul>
								<div class="d-flex flex-column mt-4" style="z-index: 5">
									<button (click)="loadMoreNotifications()" *ngIf="meta.current_page !== meta.last_page"
											class="btn btn-outline-primary mx-4">Load More
									</button>
									<div class="show-more fs-12 text-center">
										Showing {{ meta.to }} of {{ meta.total }} results
									</div>
								</div>
							</div>
						</div>
						<!-- END Alert List !-->
					</div>
				</div>
				<!-- EEND Alerts View !-->
			</div>
		</tab>
	</tabset>
	<a class="btn-icon-link invert quickview-toggle" (click)="toggle()"><i class="pg-icon">close</i></a>
</div>
