<div class="pgn push-on-sidebar-open pgn-{{options$.Style}}">
    <div class="alert alert-{{Message.type}}">
        <div *ngIf="options$.Style === 'bar'; else determineBlock" [class.container]="enableHorizontalContainer$">
            <div>
                <span>{{ Message.content }}</span>
                <button (click)="onClickClose()" *ngIf="Message.options.Action !== 'reload'; else reloadButton"
                        class="close" type="button"><span aria-hidden="true">×</span><span class="sr-only">Close</span>
                </button>
            </div>
        </div>
        <ng-template #determineBlock>
            <div *ngIf="options$.Style === 'circle'; else standardBlock">
                <div class="pgn-thumbnail">
                    <div>
                        <img alt="" height="40" src="{{options$.imgURL}}" style="display: inline-block;" width="40">
                    </div>
                </div>
                <div class="pgn-message">
                    <div>
                        <p class="bold">{{ options$.Title}}</p>
                        <p>{{ Message.content }}</p>
                    </div>
                </div>
                <button (click)="onClickClose()" *ngIf="Message.options.Action !== 'reload'; else reloadButton" class="close"
                        type="button"><span aria-hidden="true">×</span><span class="sr-only">Close</span>
                </button>
            </div>
        </ng-template>
        <ng-template #standardBlock>
            <div>
                <span>{{ Message.content }}</span>
                <button (click)="onClickClose()" *ngIf="Message.options.Action !== 'reload'; else reloadButton"
                        class="close" type="button"><span aria-hidden="true">×</span><span class="sr-only">Close</span>
                </button>
            </div>
        </ng-template>

        <ng-template #reloadButton>
            <button (click)="onClickReload()" class="btn btn-outline-{{Message.type}}" style="margin-left: 24px;"
                    type="button">
                {{ Message.options.Action | titlecase }}
            </button>
        </ng-template>
    </div>
</div>
