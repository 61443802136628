import { Component, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dg-blank-layout',
  templateUrl: './blank-layout.component.html',
  styleUrls: ['./blank-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlankLayoutComponent {
  @ViewChild('root', { static: false }) root;
}
