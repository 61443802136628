import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
    AllNotificationMarkAsReadFailed, AllNotificationMarkAsReadRequested,
    AllNotificationMarkAsReadSuccess,
    AllNotificationsLoaded,
    AllNotificationsLoadFailed,
    NotificationActionTypes,
    NotificationMarkAsReadFailed, NotificationMarkAsReadRequested,
    NotificationMarkAsReadSuccess, NotificationSocketReceived, NotificationSocketUpdate
} from '../_actions/notification.actions';
import { Link, Meta } from '../_models/pagination.model';
import { UserNotificationService } from '../../../../services/user-notification.service';
import { Notification } from '../_models/notification.model';
import { NotificationService } from '../../../layout/components/notification/notification.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { selectAllNotifications, selectNewNotifications } from '../_selectors/notification.selectors';

@Injectable()
export class NotificationEffects {

    loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActionTypes.AllNotificationsRequested),
            mergeMap((action: any) => {
                const payloadRequest = action.payload as any;
                return this.notificationService.getAllNotifications(payloadRequest).pipe(
                    map((result: any) => {
                            result.data.map((d) => d.showDetails = false);
                            return new AllNotificationsLoaded({
                                data: result.data as Notification[],
                                meta: result.meta as Meta,
                                links: result.links as Link,
                            });
                        }
                    ),
                    catchError((err, caught) => of(new AllNotificationsLoadFailed({ err, caught })))
                );
            })
        ));

    markAllASRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AllNotificationMarkAsReadRequested>(NotificationActionTypes.AllNotificationMarkAsReadRequested),
            mergeMap((action: any) => {
                const notifications = action.payload.notifications as Notification[];
                return this.notificationService.markAllASRead().pipe(
                    map((result: any) => {
                            return new AllNotificationMarkAsReadSuccess({
                                notifications
                            });
                        }
                    ),
                    catchError((err, caught) => of(new AllNotificationMarkAsReadFailed({ err, caught })))
                );
            })
        ));

    markASRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType<NotificationMarkAsReadRequested>(NotificationActionTypes.NotificationMarkAsReadRequested),
            mergeMap((action: any) => {
                const notification = action.payload.notification as Notification;
                return this.notificationService.markASRead(notification.id).pipe(
                    map((result: any) => {
                            return new NotificationMarkAsReadSuccess({
                                notification
                            });
                        }
                    ),
                    catchError((err, caught) => of(new NotificationMarkAsReadFailed({ err, caught })))
                );
            })
        ));

    socketNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType<NotificationSocketReceived>(NotificationActionTypes.NotificationSocketReceived),
            withLatestFrom(this.store.select(selectAllNotifications)),
            tap((action: any) => {
                this.notification.success((action[0].payload.notification as Notification).data.message, {
                    Position: 'bottom-right',
                    Style: 'bar',
                    Duration: 2000,
                });
            })
        ),
        { dispatch: false }
    );

    constructor(private actions$: Actions,
                private store: Store<AppState>,
                private notificationService: UserNotificationService,
                private notification: NotificationService) {
    }
}
